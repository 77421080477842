import React, { Component } from 'react';
import BattleCover from './battle-cover.jpg';
import BattleLogo from './battle-logo.png';

class Battle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
    };
  }

  render() {
    return (
      <div className="battle">
        <div className='row'>
          <div className="col-12 text-center mt-5">
            <img
              className="battle-cover"
              src={BattleCover}
              alt="Ragada Dugada Battle Cover"
            ></img>
          </div>
        </div>
        <div className='row'>
        {this.props.lang === 'en' ? (
          <div className="col-12 text-center mt-5 battle-info">
            <p>
              <strong>Ragada Dugada Battle</strong>, part of{' '}
              <strong>Ragada Dugada Music Festival</strong> will take place on
              4th of August 2024 at White Stage Bansko. There will be two battle
              formats - "2 vs 2" and "7 2 Smoke"
            </p>
            <p>
              We have prepared a Price Budget of <strong>1800 BGN</strong> as
              well as products from sponsors, free cold beers for the winners
              and all the good vibes of Ragada Dugada Music Festival
            </p>
            <p>The event will be hosted by NAS (Nasko Marinov)</p>
            <p>
              Responsible for all the good music and battle breaks will be the
              one and only DJ Skill.
            </p>
            <p>
              And how could there be a real battle if there are no judges, so
              let us present to you our selection of masters of the fine art of
              BBoying:
            </p>
            <ul className="judges-list">
              <li>Fuego - Monstribe / Zulu Kings</li>
              <li>Zak The Thriller - Monstribe / Balkan Mafia</li>
              <li>Scavenger - SleepWalkingZ / Balkan Mafia / ATD</li>
            </ul>
            <p>
              Ragada Dugada Battle is a TRUE HIP-HOP event where the accent is
              on BBoying, battling and all the great moves of a dedicated BBoy /
              BGirl. But as part of this event, we will have also DJ Skill,
              Graffiti artists making a workshop and last but not least - QTEK
              Crew from Smolyan will fill up the last missing element - the MCs.
              All elements, one event - Ragada Dugada Battle, 4 August, White
              Stage Bansko. Stay tuned ...{' '}
            </p>
            <div className="col-12 text-center mt-5 logo-container">
              <img className="logo" src={BattleLogo} alt="logo"></img>
            </div>
          </div>
        ) : (
          <div className="col-12 text-center mt-5 battle-info">
            <p>
              <strong>Битка Рагада Дугада</strong>, част от{' '}
              <strong>Музкален Фестивал Рагада Дугата</strong> ще се проведе на
              4ти Август 2024 на Бяла Сцена Банско. Битката ще се проведе в 2
              формата - "2 vs 2" и "7 2 Smoke"
            </p>
            <p>
              Подготвили сме награден фонд от <strong>1800 лв</strong> както и
              допълнителни предметни награди от нашите спонсори, безплатни
              студени бири за победителите и цялата добра енергия на Музикален
              Фестивал Рагада Дугада
            </p>
            <p>Домакин и водещ на събитето ще бъде NAS (Наско Маринов)</p>
            <p>
              Отговорен за добрата музика и батъл брейковете ще бъде
              единственият и неповторим DJ Skill
            </p>
            <p>
              И как би могло да има истинска битка ако си нямаме квалифицирано
              жури? Затова нека ви представим съдиите на Рагада Дугада Батъл:
            </p>
            <ul className="judges-list">
              <li>Fuego - Monstribe / Zulu Kings</li>
              <li>Zak The Thriller - Monstribe / Balkan Mafia</li>
              <li>Scavenger - SleepWalkingZ / Balkan Mafia / ATD</li>
            </ul>
            <p>
              Рагада Дугада Батъл е HIP-HOP събитие, където акцента е върху
              танца, битката и невероятните движения на един истински посветен
              би бой или би гърл. Но като част от това събитие, ние ще имаме DJ,
              Графити артисти, които ще направят уоркшоп по време на батъла. И
              накрая, но не на последно място - QTEK Crew от Смолян ще добавят и
              последния елемент от хип хопа, а именно рапърите. Всички елементи,
              едно събитие - Рагада Дугада Батъл, 4 Август, Бяла Сцена Банско.
              Очаквайте скоро...{' '}
            </p>
          </div>
        )}
        </div>
        <div className='row'>
        <div className="col-12 videoWrapper mt-5">
          <iframe
            width="560"
            height="349"
            src="https://www.youtube.com/embed/t1_n0CE8n4M?si=-c0_JyAdsJPeVXh_"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        </div>
      </div>
    );
  }
}

export default Battle;
