import React, { Component } from 'react';
// import { useTranslation } from "react-i18next";
// import './i18n';s
import { Route } from 'react-router-dom';

import Observer from './util/Observer';

import Home from './components/Home';
import Navigation from './components/common/navigation/Navigation';
import Notification from './components/common/Notification';
import Footer from './components/common/Footer';

import LineUp from './components/line-up/lineup';
import WhiteStage from './components/white-stage/white-stage';
import Battle from './components/battle/battle';
import Tickets from './components/tickets/tickets';
import Artist from './components/line-up/artists/artist';
import Skiller from './components/line-up/Skiller.jpg';
// import Skiller2 from './components/line-up/Skiller-second.jpg';
import CoolDen from './components/line-up/Coolden.jpeg';
import Trigaida from './components/line-up/Trigaida.jpg';
import DJ89 from './components/line-up/89.jpg';
import DJ89_Natali from './components/line-up/dj89-natali.jpg';
import Greesh from './components/line-up/Greesh.jpg';
import QTEK from './components/line-up/QTEK.jpg';
import Skill from './components/line-up/Skill.jpg';
import Alphabet from './components/line-up/Alphabet.jpg';
import SamTheMann from './components/line-up/Sam2.jpg';
import DeepGeorge from './components/line-up/deep-george.jpg';
import VataffProject from './components/line-up/vataff.jpg';
import SilentCitizen from './components/line-up/silent-citizen.jpg';
import Gatoff from './components/line-up/gatof.jpeg';
import KristofferBeatrider from './components/line-up/beatrider.jpeg';
import IvanShopov from './components/line-up/ivan_shopov.jpg';
import Beatoven from './components/line-up/boby.jpeg';
import Extazia from './components/line-up/extazia.jpg';
import DylanKay from './components/line-up/dylan_.jpg';
import DjSteven from './components/line-up/steven.png';
import DarioBulgario from './components/line-up/DARIO-BULGARIO.jpg';
// import Skiller from './components/line-up/artists/skiller';

import artists from './artists';
import Activities from './components/activities/activities';
import Partners from './components/partners/partners';
import handleScroll from './components/HandleScroll';

import './styles/main.scss';

const ragadaDugadaStartTime = new Date('Aug 2, 2024 18:00:00').getTime();

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: false,
      isAdmin: false,
      user: null,
      successMessage: '',
      errorMessage: '',
      lang: 'bg',
      countdown: '',
      scrollTop: 0,
    };

    this.processAuthResponse = this.processAuthResponse.bind(this);
    this.logout = this.logout.bind(this);
    this.switchLanguage = this.switchLanguage.bind(this);
    this.updateTime = this.updateTime.bind(this);
    this.interval = setInterval(this.updateTime, 1000);

    // Observer.subscribe(Observer.events.login, this.processAuthResponse);
    Observer.subscribe(Observer.events.logout, this.logout);
    Observer.subscribe(Observer.events.success, (message) =>
      this.setState({ successMessage: message }),
    );
    Observer.subscribe(Observer.events.error, (message) =>
      this.setState({ errorMessage: message }),
    );

    this.updateTime();
  }

  componentDidMount() {
    window.addEventListener('scroll', handleScroll);
  }

  processAuthResponse() {}

  componentDidUpdate() {
    if (this.state.successMessage) {
      setTimeout(() => this.setState({ successMessage: '' }), 3000);
    }

    if (this.state.errorMessage) {
      setTimeout(() => this.setState({ errorMessage: '' }), 5000);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', handleScroll);
  }

  // handleScroll(event) {
  //   const sY = window.scrollY;
  //   // console.log('~~~', sY);
  //   // this.setState({ scrollTop: sY });

  //   document.getElementById('navbarSupportedContent').classList.remove('show');
  //   if (sY < 360) {
  //     document.querySelector('.navigation').style.cssText = '';
  //     document.querySelector('.footer').className = 'footer p-2 fixed';
  //     // document.querySelector('.navbar-brand').style.cssText =
  //     //   `padding:0 ${sY * 5}px`;
  //     document.querySelector('.logo-header').style.cssText =
  //       `max-width:${675 - sY}px; margin-top:${sY / 2}px; `;
  //   } else {
  //     document.querySelector('.footer').className = 'footer p-2';

  //     document.querySelector('.navigation').style.cssText =
  //       'position:fixed; top:1vw; left:1vw; width:97vw; z-index:1000';

  //     document.querySelector('.navbar-collapse').classList.contains('show')
  //       ? (document.querySelector('.header').style.cssText =
  //           'margin-bottom:332px')
  //       : (document.querySelector('.header').style.cssText =
  //           'margin-bottom:55px');
  //   }
  // }

  switchLanguage() {
    if (this.state.lang === 'en') {
      this.setState({ lang: 'bg' });
    } else {
      this.setState({ lang: 'en' });
    }
  }

  updateTime() {
    // Get today's date and time
    let now = new Date().getTime();

    // Find the distance between now and the count down date
    let distance = ragadaDugadaStartTime - now;

    // Time calculations for days, hours, minutes and seconds
    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = String(
      Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    ).padStart(2, '0');
    let minutes = String(
      Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
    ).padStart(2, '0');
    let seconds = String(Math.floor((distance % (1000 * 60)) / 1000)).padStart(
      2,
      '0',
    );

    // Display the result in the element with id="demo"

    let newTime =
      days + ' Days, ' + hours + ':' + minutes + ':' + seconds + ' left';
    if (this.state.lang !== 'en') {
      newTime =
        'Остават ' + days + ' дни, ' + hours + ':' + minutes + ':' + seconds;
    }

    // If the count down is finished, write some text
    if (distance < 0) {
      this.setState({ countdown: "It's happening now" });
      clearInterval(this.interval);
    } else {
      this.setState({ countdown: newTime });
    }
  }

  logout() {}

  render() {
    return (
      <div className="container">
        {!/(iPad|iPhone|iPod)/g.test(navigator.userAgent) && (
          <video id="backVideo" autoPlay loop muted>
            <source src="/video/rgd-bg.mp4" type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
          </video>
        )}
        <div className="countdown">{this.state.countdown}</div>
        <Navigation lang={this.state.lang} onLangSwitch={this.switchLanguage} />
        {this.state.successMessage && (
          <Notification
            message={this.state.successMessage}
            cssStyle="success"
          />
        )}
        {this.state.errorMessage && (
          <Notification message={this.state.errorMessage} cssStyle="error" />
        )}
        <Route path="/" exact render={() => <Home lang={this.state.lang} />} />
        <Route
          path="/line-up"
          exact
          render={() => <LineUp lang={this.state.lang} />}
        />
        <Route
          path="/line-up/skiller"
          exact
          render={() => (
            <Artist
              lang={this.state.lang}
              imgSrc={Skiller}
              secondImage=""
              youTube="https://www.youtube.com/embed/oIhl8UPBqO8"
              artist={artists.skiller}
            />
          )}
        />
        <Route
          path="/line-up/DJ89"
          exact
          render={() => (
            <Artist
              lang={this.state.lang}
              imgSrc={DJ89}
              secondImage={DJ89_Natali}
              youTube="https://www.youtube.com/embed/S7VDf-VUcOM?si=m5iNmGO6tsUOzTO0"
              artist={artists.dj89}
            />
          )}
        />
        <Route
          path="/line-up/trigaida"
          exact
          render={() => (
            <Artist
              lang={this.state.lang}
              imgSrc={Trigaida}
              secondImage=""
              youTube="https://www.youtube.com/embed/FrRwD3rbglo?si=KH6MIr7NLXbGbG1j"
              artist={artists.trigaida}
            />
          )}
        />
        <Route
          path="/line-up/ivan_shopov"
          exact
          render={() => (
            <Artist
              lang={this.state.lang}
              imgSrc={IvanShopov}
              secondImage=""
              youTube="https://www.youtube.com/embed/P2xgBfqb9uU?si=h6sp4RungfkbSrdg"
              artist={artists.shopov}
            />
          )}
        />
        <Route
          path="/line-up/coolden"
          exact
          render={() => (
            <Artist
              lang={this.state.lang}
              imgSrc={CoolDen}
              secondImage=""
              youTube="https://www.youtube.com/embed/4-hUhFkDW5c?si=XgN346FuduzfMUPx"
              artist={artists.coolden}
            />
          )}
        />
        <Route
          path="/line-up/Greesh"
          exact
          render={() => (
            <Artist
              lang={this.state.lang}
              imgSrc={Greesh}
              secondImage=""
              youTube="https://www.youtube.com/embed/JXqT-6DovYE?si=ygOZ6egtPmuBgs2M"
              artist={artists.greesh}
            />
          )}
        />
        <Route
          path="/line-up/QTEK"
          exact
          render={() => (
            <Artist
              lang={this.state.lang}
              imgSrc={QTEK}
              secondImage=""
              youTube="https://www.youtube.com/embed/LlnvRc2-4_8?si=orYfj1YJknk_EYZ-"
              artist={artists.qtek}
            />
          )}
        />
        <Route
          path="/line-up/DJ_Skill"
          exact
          render={() => (
            <Artist
              lang={this.state.lang}
              imgSrc={Skill}
              secondImage=""
              youTube="https://www.youtube.com/embed/SOiJ8dYDBfw?si=dwCVjPWRr8BPSJWL"
              artist={artists.skill}
            />
          )}
        />
        <Route
          path="/line-up/DJ_Alphabet"
          exact
          render={() => (
            <Artist
              lang={this.state.lang}
              imgSrc={Alphabet}
              secondImage=""
              artist={artists.alphabet}
            />
          )}
        />
        <Route
          path="/line-up/Samm_The_Mann"
          exact
          render={() => (
            <Artist
              lang={this.state.lang}
              imgSrc={SamTheMann}
              secondImage=""
              artist={artists.samm_the_man}
            />
          )}
        />
        <Route
          path="/line-up/Deep_George"
          exact
          render={() => (
            <Artist
              lang={this.state.lang}
              imgSrc={DeepGeorge}
              secondImage=""
              youTube="https://www.youtube.com/embed/2OKe6o6YMso?si=73wwGSDOXo2QqE4y"
              artist={artists.deep_george}
            />
          )}
        />
        <Route
          path="/line-up/Vataff_Project"
          exact
          render={() => (
            <Artist
              lang={this.state.lang}
              imgSrc={VataffProject}
              secondImage=""
              artist={artists.vataff_project}
            />
          )}
        />
        <Route
          path="/line-up/Silent_Citizen"
          exact
          render={() => (
            <Artist
              lang={this.state.lang}
              imgSrc={SilentCitizen}
              secondImage=""
              artist={artists.silent_citizen}
            />
          )}
        />
        <Route
          path="/line-up/gatof"
          exact
          render={() => (
            <Artist
              lang={this.state.lang}
              imgSrc={Gatoff}
              secondImage=""
              artist={artists.gatoff}
            />
          )}
        />
        <Route
          path="/line-up/kristoffer-beatrider"
          exact
          render={() => (
            <Artist
              lang={this.state.lang}
              imgSrc={KristofferBeatrider}
              secondImage=""
              artist={artists.kristoffer_beatrider}
            />
          )}
        />
        <Route
          path="/line-up/beatoven"
          exact
          render={() => (
            <Artist
              lang={this.state.lang}
              imgSrc={Beatoven}
              secondImage=""
              artist={artists.beatoven}
            />
          )}
        />
        <Route
          path="/line-up/extazia"
          exact
          render={() => (
            <Artist
              lang={this.state.lang}
              imgSrc={Extazia}
              secondImage=""
              artist={artists.extazia}
            />
          )}
        />
        <Route
          path="/line-up/dylan_kay"
          exact
          render={() => (
            <Artist
              lang={this.state.lang}
              imgSrc={DylanKay}
              secondImage=""
              artist={artists.dylan_kay}
            />
          )}
        />
        <Route
          path="/line-up/dj_steven"
          exact
          render={() => (
            <Artist
              lang={this.state.lang}
              imgSrc={DjSteven}
              secondImage=""
              youTube="https://www.youtube.com/embed/V3isIihmeLg?si=WlakozdM8PuVfkfC"
              artist={artists.steven}
            />
          )}
        />
        <Route
          path="/line-up/dario_bulgario"
          exact
          render={() => (
            <Artist
              lang={this.state.lang}
              imgSrc={DarioBulgario}
              secondImage=""
              youTube=""
              artist={artists.dario}
            />
          )}
        />

        {/* <Route path='/line-up/dj89' exact render={() => <Artist imgSrc={DJ8} artist="Skiller"/>}/> */}
        <Route
          path="/Activities"
          exact
          render={() => <Activities lang={this.state.lang} />}
        />
        <Route
          path="/white-stage"
          exact
          render={() => <WhiteStage lang={this.state.lang} />}
        />
        {/* <Route
          path="/battle"
          exact
          render={() => <Battle lang={this.state.lang} />}
        /> */}
        <Route
          path="/tickets"
          exact
          render={() => <Tickets lang={this.state.lang} />}
        />
        <Partners lang={this.state.lang} />
        <Footer lang={this.state.lang} />
      </div>
    );
  }
}

export default App;
