import React from 'react';
import { NavLink } from 'react-router-dom';
import FadeIn from 'react-fade-in';

const Artists = () => {
  const scrollTop = 369;
  return (
    <div className="col-12 text-center mt-3 artists-list">
      <FadeIn>
        <ul className="top-item">
          <li>
            <NavLink
              activeClassName="active"
              exact
              className="nav-link"
              to="/line-up/SkilleR"
              onClick={() =>
                window.scrollTo({ top: scrollTop, behavior: 'smooth' })
              }
            >
              SkilleR
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              exact
              className="nav-link"
              to="/line-up/CoolDen"
              onClick={() =>
                window.scrollTo({ top: scrollTop, behavior: 'smooth' })
              }
            >
              COOL DEN
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              exact
              className="nav-link"
              to="/line-up/ivan_shopov"
              onClick={() =>
                window.scrollTo({ top: scrollTop, behavior: 'smooth' })
              }
            >
              Ivan Shopov
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              exact
              className="nav-link"
              to="/line-up/Trigaida"
              onClick={() =>
                window.scrollTo({ top: scrollTop, behavior: 'smooth' })
              }
            >
              Trigaida
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              exact
              className="nav-link"
              to="/line-up/DJ89"
              onClick={() =>
                window.scrollTo({ top: scrollTop, behavior: 'smooth' })
              }
            >
              DJ89
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              exact
              className="nav-link"
              to="/line-up/Greesh"
              onClick={() =>
                window.scrollTo({ top: scrollTop, behavior: 'smooth' })
              }
            >
              GREESH
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              exact
              className="nav-link"
              to="/line-up/dj_steven"
              onClick={() =>
                window.scrollTo({ top: scrollTop, behavior: 'smooth' })
              }
            >
              DJ Steven <small>- Metropolis / MKNK</small>
            </NavLink>
          </li>
        </ul>
        <ul className="reg-item">
          <li>
            <NavLink
              activeClassName="active"
              exact
              className="nav-link"
              to="/line-up/DJ_Alphabet"
              onClick={() =>
                window.scrollTo({ top: scrollTop, behavior: 'smooth' })
              }
            >
              DJ Alphabet
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              exact
              className="nav-link"
              to="/line-up/beatoven"
              onClick={() =>
                window.scrollTo({ top: scrollTop, behavior: 'smooth' })
              }
            >
              Beatoven
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              exact
              className="nav-link"
              to="/line-up/Silent_Citizen"
              onClick={() =>
                window.scrollTo({ top: scrollTop, behavior: 'smooth' })
              }
            >
              Silent Citizen
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              exact
              className="nav-link"
              to="/line-up/QTEK"
              onClick={() =>
                window.scrollTo({ top: scrollTop, behavior: 'smooth' })
              }
            >
              QTEK Crew
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              exact
              className="nav-link"
              to="/line-up/DJ_Skill"
              onClick={() =>
                window.scrollTo({ top: scrollTop, behavior: 'smooth' })
              }
            >
              DJ Skill
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              exact
              className="nav-link"
              to="/line-up/gatof"
              onClick={() =>
                window.scrollTo({ top: scrollTop, behavior: 'smooth' })
              }
            >
              [ gatof ]
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              exact
              className="nav-link"
              to="/line-up/Deep_George"
              onClick={() =>
                window.scrollTo({ top: scrollTop, behavior: 'smooth' })
              }
            >
              Deep George
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              exact
              className="nav-link"
              to="/line-up/Samm_The_Mann"
              onClick={() =>
                window.scrollTo({ top: scrollTop, behavior: 'smooth' })
              }
            >
              Samm The Man
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              exact
              className="nav-link"
              to="/line-up/kristoffer-beatrider"
              onClick={() =>
                window.scrollTo({ top: scrollTop, behavior: 'smooth' })
              }
            >
              Kristoffer Beatrider
            </NavLink>
          </li>
          {/* <li>
            <a  href="/line-up/"  onClick={() =>
              window.scrollTo({ top: scrollTop, behavior: 'smooth' })
            }>vankata the turntablist</NavLink>
          </li> */}
          <li>
            <NavLink
              activeClassName="active"
              exact
              className="nav-link"
              to="/line-up/Vataff_Project"
              onClick={() =>
                window.scrollTo({ top: scrollTop, behavior: 'smooth' })
              }
            >
              vataff project
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              exact
              className="nav-link"
              to="/line-up/dylan_kay"
              onClick={() =>
                window.scrollTo({ top: scrollTop, behavior: 'smooth' })
              }
            >
              Dylan Kay
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              exact
              className="nav-link"
              to="/line-up/extazia"
              onClick={() =>
                window.scrollTo({ top: scrollTop, behavior: 'smooth' })
              }
            >
              Extazia
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              exact
              className="nav-link"
              to="/line-up/dario_bulgario"
              onClick={() =>
                window.scrollTo({ top: scrollTop, behavior: 'smooth' })
              }
            >
              Dario Bulgario
            </NavLink>
          </li>
        </ul>
      </FadeIn>
    </div>
  );
};

export default Artists;
