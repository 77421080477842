import React, { Component } from 'react';
import FadeIn from 'react-fade-in';
import DJ89 from './89.jpg';
import Skiller from './Skiller.jpg';
import Trigaida from './Trigaida.jpg';
import IvanShopov from './ivan_shopov.jpg';
import Coolden from './Coolden.jpeg';
import Greesh from './Greesh.jpg';
import QTEK from './QTEK.jpg';
import Skill from './Skill.jpg';
import Alphapbet from './Alphabet.jpg';
import DeepGeorge from './deep-george.jpg';
import Sam from './Sam2.jpg';
import VataffProject from './vataff.jpg';
import SilentCitizen from './silent-citizen.jpg';
import Gatof from './gatof.jpeg';
import KristofferBeatrider from './beatrider.jpeg';
import Beatoven from './boby.jpeg';
import Extazia from './extazia.jpg';
import DylanKay from './dylan_.jpg';
import DjSteven from './steven.png';
import DarioBulgario from './DARIO-BULGARIO.jpg';

import ArtistBox from './artist-box';
// import RagadaDugadaPoster from './ragada-dugada-poster.jpeg';

class LineUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
    };
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-12 col-md-6 line-up" id="line-up">
            <h1>{this.props.lang === 'en' ? 'Line Up' : 'Програма'}</h1>
            <h2 className="lineup-title">
              {this.props.lang === 'en'
                ? 'Friday, 02 August'
                : 'Петък, 02 Август'}
            </h2>
            <FadeIn>
              <ul>
                <li className="linup-item">
                  18:00 Vankata The Turntablist - Festival Warm Up Set
                </li>
                <li className="linup-item">
                  20:30 DJ 89 & Natali Stefanova - Live Performance
                </li>
                <li className="linup-item">
                  22:00 SkilleR & Vili Marinova - Live Performance
                </li>
                <li className="linup-item">22:30 Trigaida - Live Performace</li>
                <li className="linup-item">00:00 Ivan Shopov - DJ Set</li>
                <li className="linup-item">
                  01:30 Vataff Project - Live DJ Set
                </li>
                <li className="linup-item">03:00 Silent Citizen - DJ Set</li>
                <li className="linup-item">
                  04:30 Dario Bulgario - Live DJ Set
                </li>
              </ul>
            </FadeIn>

            <h2 className="lineup-title">
              {this.props.lang === 'en'
                ? 'Saturday, 03 August'
                : 'Събота, 03 Август'}
            </h2>
            <FadeIn>
              <ul>
                <li className="linup-item">
                  18:00 Beatoven - Vinyl Reggae Selection
                </li>
                <li className="linup-item">
                  19:30 DJ Sergio - Sunset Summer Mix
                </li>
                <li className="linup-item">
                  21:30 Music Selection by Krasi Elinov (The Host)
                </li>
                <li className="linup-item">23:00 Greesh - Live Performance</li>
                <li className="linup-item">00:30 Deep George - DJ Set</li>
                <li className="linup-item">
                  02:00 DJ Steven - Metropolis, MKNK Recordings
                </li>
                <li className="linup-item">03:30 Dylan Kay - Techno DJ Set</li>
                <li className="linup-item">05:00 Extazia - Techno DJ Duo</li>
              </ul>
            </FadeIn>

            <h2 className="lineup-title">
              {this.props.lang === 'en'
                ? 'Sunday, 04 August'
                : 'Неделя, 04 Август'}
            </h2>
            <FadeIn>
              <ul>
                <li className="linup-item">18:30 DJ Skill (Soul Searchin)</li>
                <li className="linup-item">21:30 QTEK Crew - Live Performance</li>
                <li className="linup-item">22:30 Cool Den - Live Performance</li>
                <li className="linup-item">00:00 DJ Alphabet - DJ SET</li>
                <li className="linup-item">02:00 Samm The Man - DJ SET</li>
                <li className="linup-item">04:00 [gatof] - Live SET</li>
                <li className="linup-item">04:45 Kristoffer Beatrider (FUNKADELIA) - Psy Trance DJ Set</li>
              </ul>
            </FadeIn>
          </div>

          <div className="col-sm-12 col-md-6 artists" id="artists">
            <h1>{this.props.lang === 'en' ? 'Artists' : 'Артисти'}</h1>
            <div className="row">
              <ArtistBox imgSrc={DJ89} artist="DJ 89" link="DJ89" />
              <ArtistBox
                imgSrc={Skiller}
                artist={this.props.lang === 'en' ? 'SkilleR' : 'Скилър'}
                link="SkilleR"
              />
            </div>

            <div className="row">
              <ArtistBox
                imgSrc={Trigaida}
                artist={this.props.lang === 'en' ? 'Trigaida' : 'Тригайда'}
                link="Trigaida"
              />
              <ArtistBox imgSrc={Coolden} artist="Cool Den" link="CoolDen" />
            </div>

            <div className="row">
              <ArtistBox
                imgSrc={Greesh}
                artist={this.props.lang === 'en' ? 'Greesh' : 'Грийш'}
                link="Greesh"
              />
              <ArtistBox
                imgSrc={IvanShopov}
                artist={this.props.lang === 'en' ? 'Ivan Shopov' : 'Иван Шопов'}
                link="ivan_shopov"
              />
            </div>

            <div className="row">
              <ArtistBox
                imgSrc={DjSteven}
                artist="DJ Steven"
                link="dj_steven"
              />
              <ArtistBox
                imgSrc={DeepGeorge}
                artist="Deep George"
                link="Deep_George"
              />
            </div>

            <div className="row">
              <ArtistBox imgSrc={QTEK} artist="QTEK Crew" link="QTEK" />
              <ArtistBox imgSrc={Skill} artist="DJ Skill" link="DJ_Skill" />
            </div>

            <div className="row">
              <ArtistBox imgSrc={Extazia} artist="Extazia" link="extazia" />
              <ArtistBox
                imgSrc={DylanKay}
                artist="Dylan Kay"
                link="dylan_kay"
              />
            </div>

            <div className="row">
              <ArtistBox
                imgSrc={VataffProject}
                artist="Vataff Project"
                link="Vataff_Project"
              />
              <ArtistBox
                imgSrc={SilentCitizen}
                artist="Silent Citizen"
                link="Silent_Citizen"
              />
            </div>

            <div className="row">
              <ArtistBox
                imgSrc={Alphapbet}
                artist="DJ Alphabet"
                link="DJ_Alphabet"
              />
              <ArtistBox
                imgSrc={Sam}
                artist="Samm The Man"
                link="Samm_The_Mann"
              />
            </div>

            <div className="row">
              <ArtistBox imgSrc={Gatof} artist="[gatof]" link="gatof" />
              <ArtistBox
                imgSrc={KristofferBeatrider}
                artist="Kristoffer Beatrider"
                link="kristoffer-beatrider"
              />
            </div>

            <div className="row">
              <ArtistBox imgSrc={Beatoven} artist="Beatoven" link="beatoven" />
              <ArtistBox
                imgSrc={DarioBulgario}
                artist="Dario Bulgario"
                link="dario_bulgario"
              />
            </div>
          </div>
        </div>

        {/* <div className='row'>
                    <div className='col-12'>
                        <img className='lineup-image' src={RagadaDugadaPoster} alt="Ragada Dugada Poster"/>
                    </div>
                </div> */}
      </div>
    );
  }
}

export default LineUp;
