import React, { Component } from 'react';
import FadeIn from 'react-fade-in';
// import FestCover from './fest-cover.jpg';
// import ArtistSlider from './line-up/slider/slider';
import ShuttleEn from './IMG_2560.gif';
import ShuttleBg from './IMG_2558.gif';
import KidsEn from './kids-en.png';
import KidsBg from './kids-bg.png';
// import Givaway from './givaway.gif';
// import PromoEn from './promo-en.gif';
// import PromoBg from './promo-bg.gif';
import RgdMap from './rgd_map-web.jpg';
import RgdMapEN from './rgd_map-web-en.jpg';
import ArtistsList from './ArtistsList.jsx';

const messages_en = [
  'Ragada Dugada Music Festival',
  'Three days full of lots of Fun and Excitement',
  '2-4 August, White Stage Bansko',
  'DJ 89, SkilleR, Trigaida, Greesh, Cool Den',
  'Ragada Dugada Battle, 04 August',
  '"2 vs 2" and "Seven To Smoke"',
];

const ticketsMessages_en = [
  'Buy now 3 Days Festival Ticket only for 65 BGN',
  'Price at the festival 80 BGN',
];

const messages_bg = [
  'Музикален Фестивал Рагада Дугада',
  'Три дни изпълнени с много музика и забавления',
  '2-4 Август, Бяла Сцена Банско',
  'DJ 89, Скилър, Тригайда, Грийш, Cool Den',
  'Битка Рагада Дугада, 04 Aвгуст',
  '"2 vs 2" и "Seven To Smoke"',
];

const ticketsMessages_bg = [
  'Купи сега тридневен билет само за 65лв',
  'Цена на входа на фестивала 80лв',
];

let index = 0;
let indexTickets = 0;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message:
        this.props.lang === 'en' ? messages_en[index] : messages_bg[index],
      ticketsMessages:
        this.props.lang === 'en'
          ? ticketsMessages_en[indexTickets]
          : ticketsMessages_bg[indexTickets],
    };
  }

  componentDidMount() {
    this.timerId = setInterval(() => {
      index = (index + 1) % messages_en.length;
      indexTickets = (indexTickets + 1) % ticketsMessages_en.length;
      this.setState({
        message:
          this.props.lang === 'en' ? messages_en[index] : messages_bg[index],
        ticketsMessages:
          this.props.lang === 'en'
            ? ticketsMessages_en[indexTickets]
            : ticketsMessages_bg[indexTickets],
      });
    }, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  render() {
    return (
      <div className="row welcome">
        <ArtistsList />

        <div className="col-12 text-center mt-5">
          <a
            href="https://bilet.bg/bg/cart/ragada-dugada-music-festival-5244-5244"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FadeIn>
              <h2 className="tickets-message">{this.state.ticketsMessages}</h2>
            </FadeIn>
          </a>
        </div>

        {/* <ArtistSlider/> */}
        <div className="col-12 videoWrapper mt-5">
          <iframe
            width="560"
            height="349"
            src="https://www.youtube.com/embed/oUQvVloCxJg?si=-eVQsDQl4UGrGxbk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>

        <div className="col-12 social-link-box  text-center mt-5">
          <FadeIn>
            {this.props.lang === 'en' ? (
              <h2>
                <a
                  className="social-link"
                  href="https://www.facebook.com/events/798180282419173"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Join "Ragada Dugada Music Festival" Facebook Event
                </a>
              </h2>
            ) : (
              <h2>
                <a
                  className="social-link"
                  href="https://www.facebook.com/events/798180282419173"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Присъединете се към събитието ни във Facebook
                </a>
              </h2>
            )}
          </FadeIn>
        </div>

        <div className="col-12 mt-5 home-info">
          <FadeIn>
            <img
              src={this.props.lang === 'bg' ? RgdMap : RgdMapEN}
              alt="Ragada Dugada Map"
            ></img>
          </FadeIn>

          {this.props.lang === 'en'
            ? 'There will be a huge FREE camp site around the stage where all guests of the festival could place their tents or campers. The organizers will provide kids playground, bicycles for rent as well as many other activities including HOT Air Ballons, Tandem Paragliding and many others.'
            : 'Музикален Фестивал "Рагада Дугада", 2-4 Август, Бяла Сцена Банско. Три дни музикална експлозия с множество групи, ди джеи, качествен звук и светлинно шоу. По време на фестивала ще предложим голям и напълно безплатен къмпинг, където нашите гости ще имат възможност да разпънат палатки или да паркират своите кемпери. В непосредствена близост до сцената ще подготвим детски кът с аниматори, колела под наем, както и много други активности като полет с балон или тандемен парапланер. Ще се видим скоро на RAGADA DUGADA MUSIC FESTIVAL'}
        </div>

        <div className="col-12 social-link-box text-center mt-5">
          <FadeIn>
            {this.props.lang === 'en' ? (
              <h2>
                Follow us on{' '}
                <a
                  className="social-link"
                  href="https://www.facebook.com/RagadaDugada/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>{' '}
                and{' '}
                <a
                  className="social-link"
                  href="https://www.instagram.com/ragada_dugada_official/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>
              </h2>
            ) : (
              <h2>
                Последвайте ни във{' '}
                <a
                  className="social-link"
                  href="https://www.facebook.com/RagadaDugada/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>{' '}
                и{' '}
                <a
                  className="social-link"
                  href="https://www.instagram.com/ragada_dugada_official/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>
              </h2>
            )}
          </FadeIn>
        </div>

        <div className="col-7 text-center mt-5">
          <FadeIn>
            {/* <h2>
							{this.props.lang === 'en'
								? "Free Shuttles in both directions from Bansko to White Stage during the festival days"
								: "Безплатни бусове от центъра на Банско до Бяла Сцена през трите дни на фестивала"
							}
						</h2> */}
            <div>
              <img
                className="shuttle-img"
                src={this.props.lang === 'en' ? ShuttleEn : ShuttleBg}
                alt=""
                style={{ maxHeight: 742, maxWidth: 742 }}
              />
            </div>
          </FadeIn>
        </div>

        <div className="col-5 text-center mt-5">
          <FadeIn>
            <div>
              <img
                className="kids-img"
                src={this.props.lang === 'en' ? KidsEn : KidsBg}
                alt=""
              />
            </div>
          </FadeIn>
        </div>

        {/* <div className="col-12 text-center mt-5">
          <FadeIn>
            <h2 className="welcome-message">{this.state.message}</h2>
          </FadeIn>
        </div> */}

        <div className="col-12 social-link-box text-center mt-5">
          <FadeIn>
            <h2>
              {this.props.lang === 'en'
                ? 'Free entrance for kids up to 12 years old'
                : 'Безплатен вход за деца до 12 години'}
            </h2>
          </FadeIn>
        </div>
      </div>
    );
  }
}

export default Home;
