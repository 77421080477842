import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Menu from './Menu';
import header from './header.svg';
import BG from './bg.svg';
import EN from './en.svg';
class Navigation extends Component {
  onLangSwitch = () => {
    this.props.onLangSwitch();
  };

  render() {
    return (
      <div className="row header">
        <div className="header-top">
          <h1 className="left">
            2-4&nbsp;
            <br />
            {this.props.lang === 'bg' ? 'август' : 'august'}
            <br />
            2024&nbsp;
          </h1>
          <Link className="navbar-brand" to="/">
            <img
              className="logo-header"
              src={header}
              alt="Ragada Dugada logo"
            />
            <h2>
              {this.props.lang === 'en'
                ? 'music festival'
                : 'музикален фестивал'}
            </h2>
          </Link>
          <h1 className="right">
            {this.props.lang === 'en' ? 'White' : 'Бяла'} <br />
            {this.props.lang === 'en' ? 'Stage' : 'сцена'} <br />
            {this.props.lang === 'en' ? 'Bansko' : 'Банско'}
          </h1>
        </div>
        <nav className="navbar navbar-expand-md navbar-dark navigation">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <Menu lang={this.props.lang} />
            <div className="lang-container" onClick={this.onLangSwitch}>
              {this.props.lang === 'en' ? (
                <img className="lang" alt="избери българското" src={BG} />
              ) : (
                <img className="lang" src={EN} alt="set lang EN" />
              )}
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default Navigation;
