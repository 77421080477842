import React from 'react';

function HandleScroll(event) {
  const sY = window.scrollY;
  // console.log('~~~', sY);
  // this.setState({ scrollTop: sY });

  document.getElementById('navbarSupportedContent').classList.remove('show');
  if (sY < 360) {
    document.querySelector('.navigation').style.cssText = '';
    document.querySelector('.footer').className = 'footer p-2 fixed';
    // document.querySelector('.navbar-brand').style.cssText =
    //   `padding:0 ${sY * 5}px`;
    document.querySelector('.logo-header').style.cssText =
      `max-width:${675 - sY}px; margin-top:${sY / 2}px; `;
  } else {
    document.querySelector('.footer').className = 'footer p-2';

    document.querySelector('.navigation').style.cssText =
      'position:fixed; top:1vw; left:1vw; width:97vw; z-index:1000';

    document.querySelector('.navbar-collapse').classList.contains('show')
      ? (document.querySelector('.header').style.cssText =
          'margin-bottom:332px')
      : (document.querySelector('.header').style.cssText =
          'margin-bottom:55px');
  }
}

export default HandleScroll;
