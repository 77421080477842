import React, { Component } from 'react';

class AccuracyBg extends Component {

    render() {
		return (
            <div className='row activities-row'>
                <div className='col-12'>
                    <h1>Състезание по целно кацане, Банско 2024</h1>
                    <h3>01 - 04 Август, 2024, Банско, България</h3>
                    <p>Това лято, в красивия планински курорт Банско, ще се проведе международно състезание по целно кацане с парапланер.
                        Всички пилоти които имат желание да участват в тази дисциплина и имат нужните умения и опит, са добре дошли.
                        Целта ни е да имаме общо 12 полета за трите състезателни дни.
                    </p>
                    <iframe className='accuracy-video' width="100%" height="400px" src="https://www.youtube.com/embed/1ayoiYK8p1g?si=EFlsquG2YnG9F0vc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div className='col-sm-12 col-md-6'>
                    <h2>Програма на състезанието</h2>
                    <ul>
                        <li>01 август - Tренировъчен ден</li>
                        <li>02 август - Състезателен ден
                            <ul>
                                <li>09:00 - 10:00 - Регистрация/ Брифинг / Тренировъчен полет</li>
                                <li>10:00 - 18:00 - Състезателни полети</li>
                            </ul>
                        </li>
                        <li>03 август - Състезателен ден
                            <ul>
                                <li>08:00 - 18:00 - Състезателни полети</li>
                                <li>19:00 - Награждаване и закриваща церемония</li>
                            </ul>
                        </li>
                        <li>04 август - Резервен ден</li>
                    </ul>
                    <p>Организаторите на събитието ще бъдат на Бъндеришка Поляна (горна станция на кабинковия лифт).
                        Всички модификации или промени ще бъдат обявени навреме от организаторите на таблото за обща информация в централата или на брифингите.
                    </p>
                </div>
                <div className='col-sm-12 col-md-6'>
                    <h2>Правила за сугурност</h2>
                    <p>Могат да участват само опитни пилоти в дисциплината целно кацане. Оргаризаторите си запазват правото да откажат участие на пилот без доказан опит в целното кацане.</p>
                    <h2>Такса за участие CHF 50</h2>
                    <p>Таксата за участие се плаща в брой по време на регистрацията</p>
                    <h2>Ограничения</h2>
                    <p>За участие в състезанието ще бъдат допускани само сертифицирани парапланери в класовете EN - A, EN - B, EN - C, или техният еквивалент</p>
                    <h2>Информация за мястото</h2>
                    <ul>
                        <li>Старт: 2230 метра надморска височина. Подходящи посоки на вятъра: N, NW, NE</li>
                        <li>Кацалка: Бъндеришка Поляна, 1600 метра надморска височина. Тревиста настилка, подходяща за заход от всички посоки.</li>
                    </ul>
                    <h2>Организатори</h2>
                    <ul>
                        <li>Валери Цветанов</li>
                        <li>Иван Калибацев</li>
                    </ul>
                    <h2>Контакти</h2>
                    <ul>
                        <li>Валери Цветанов</li>
                        <li>+359899914801</li>
                        <li>freefever@abv.bg</li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default AccuracyBg;