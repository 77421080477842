import React, { Component } from 'react';
// import FadeIn from 'react-fade-in';
import './activities.css';

import GraffitiImage_04 from './gtaffiti_images/graffiti_04.JPG';
import GraffitiImage_05 from './gtaffiti_images/graffiti_05.JPG';
import GraffitiImage_11 from './gtaffiti_images/graffiti_11.JPG';
import GraffitiImage_12 from './gtaffiti_images/graffiti_12.JPG';

import LotusVibeLogo from './lotus-vibe.jpg';
import FuriLogo from './furi-logo.jpg';
import FuriCover from './furi-cover.jpg';

import Balon1 from './ballon_images/baloni_1.jpg';
import Balon2 from './ballon_images/baloni_2.jpg';
import Balon3 from './ballon_images/baloni_3.jpg';
import Balon4 from './ballon_images/baloni_4.jpg';
import AccuracyEn from './accuracy/accuracyEn';
import AccuracyBg from './accuracy/accuracyBg';

const graffitiBg = [
  'Донеси си бяла тениска, тръгни си с изкуство за обличане.',
  'По време на фестивала, от 18.00 до 20.00ч, всеки който си донесе бяла тениска ще има възможност да я изрисува с графити спрей, заедно с наш артист, част от екипа на Sofia Graffiti Tour.',
  'За да участваш, намери нашата шатра, откъдето може да си купиш билет за участие на цена от 20лв.',
  'Знаем, че бялата тениска е класика в гардероба, затова те мотивираме да я донесеш и да я превърнеш в нещо, което ще искаш да носиш всеки ден.',
];

const graffitiEn = [
  'Bring a white t-shirt and turn it into a graffiti art piece.',
  'During the festival, from 6:00 p.m. to 8:00 p.m., anyone can bring a white t-shirt for the opportunity to paint it with graffiti spray, together with our artist, part of "Sofia Graffiti Tour" team.',
  'To participate, find our tent, where you can buy a participation ticket at a price of BGN 20.',
  "We know the white t-shirt is a wardrobe classic, so we encourage you to bring it and turn it into something you'll want to wear every day.",
];

const kidsSessionsEn = [
  'Games to improve communication skills and teamwork, building trust, relay games',
  'Creative workshops including a variety of techniques (painting with different materials, modeling)',
  'Experiments - STEAM Models',
  'Yoga Practices',
];

const kidsSessionsBg = [
  'Игри за комуникация и работа в екип, за изграждане на доверие, щафетни игри',
  'Творчески ателиета включващи разнообразие от техники (рисуване с различни материали, моделиране)',
  'Експерименти - STEAM модели',
  'Практики от Йогата',
];

const kidsSessionHoursEn = [
  'Session 1 - 16:00/17:30',
  'Session 2 - 18:00/19:30',
  'Session 3 - 20:00/21:30',
];

const kidsSessionHoursBg = [
  'Сесия 1 - 16:00/17:30 ',
  'Сесия 2 - 18:00/19:30',
  'Сесия 3 - 20:00/21:30',
];

const baloonTextEn = [
  'Share with us the oldest dream. A hot air balloon flight, a great gift idea and a romantic adventure!',
  'Since the memorable day in June 1783, when the Montgolfier brothers raised their first balloon over Paris, to this day people continue to admire the elegance and ease with which one can rise into the air and float in the sky. A lot of time has passed since then, but still only few things can compare with the splendor and flight of the hot-air balloon, capturing the imagination like no other flying machine.',
  'Club Aeroact will be our guest at Ragada Dugada Fest, where they will do tethered ascents. With them, the hot air balloon is fixed to the ground with ropes, preventing it from flying away freely with the air currents. Typical of them is the large number of people who fly during the events with the balloon often aloft for several hours, generating great public and media interest.',
  'The flights will take place early in the morning and late in the afternoon when the atmosphere is calmest. Each flight lasts about 10 minutes and allows for up to 4 people on board.',
  'It is important to note that free ballooning is extremely dependent on weather conditions, so we cannot guarantee that they will happen. May the weather be with us:))',
];

const baloonTextBg = [
  'Споделете с нас най-старата мечта. Полет с балон, чудесна идея за подарък и романтично приключение!',
  'От паметния ден през юни 1783, когато братята Монголфие издигат първия си балон над Париж, до днес хората продължават да се възхищават на елегантността и лекотата, с която човек може да се издигне във въздуха и заплува в небето. Оттогава е изминало много време, но все още малко неща могат да се сравнят с великолепието и полета на топловъздушния аеростат, улавящ въображението както никоя друга летяща машина.',
  'Клуб Аероакт ще ни гостуват на Рагада Дугада Фест, където ще направят привързани издигания. При тях топловъздушният балон е фиксиран за земята с въжета, възпрепятстващи свободното му отлитане с въздушните течения. Характерно за тях е големият брой хора, които летят по време на събитията като често балонът е издигнат във въздуха за няколко часа, предизвиквайки голям обществен и медиен интерес.',
  'Полетите ще се осъществяват рано сутрин и късно следобед когато атмосферата е най-спокойна. Всеки полет трае около 10 минути, като на полет могат да се качват по 4 човека.',
  'Важно е да отбележим, че свобдният полет с балон зависи изключително много от атмосферните условия, поради което не можем да гарантираме, че те ще се случат. Нека времето бъде с нас:))',
];

class Activities extends Component {
  render() {
    return (
      <div className="activities-container">
        <div>
          {this.props.lang === 'en' ? <AccuracyEn /> : <AccuracyBg />}

          <div className="row activities-row">
            <div className="col-12">
              <a
                href="https://sofiagraffititour.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h1 className="activities-title">
                  {this.props.lang === 'en'
                    ? 'Graffiti Workshop by Sofia Graffiti Tour'
                    : 'Графити Работилница от Sofia Graffiti Tour'}
                </h1>
              </a>
            </div>
          </div>

          <div className="row activities-row">
            <div className="col-sm-12 col-md-6 graffiti-text">
              <h2 className="graffiti-title">
                {this.props.lang === 'en'
                  ? 'T-Shirts Painting'
                  : 'Рисуване върху тениски'}
              </h2>
              {this.props.lang === 'en'
                ? graffitiEn.map((e) => <p>{e}</p>)
                : graffitiBg.map((e) => <p>{e}</p>)}
              <div className="price-container">
                <span className="price-box">
                  {this.props.lang === 'en' ? 'Price 20 BGN' : 'Цена 20 лв'}
                </span>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 graffiti-images-container">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <img
                    className="activities-image"
                    src={GraffitiImage_04}
                    alt="Graffiti  01"
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <img
                    className="activities-image"
                    src={GraffitiImage_05}
                    alt="Graffiti  01"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <img
                    className="activities-image"
                    src={GraffitiImage_11}
                    alt="Graffiti  01"
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <img
                    className="activities-image"
                    src={GraffitiImage_12}
                    alt="Graffiti  01"
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="row activities-row">
              <h1>
                {this.props.lang === 'en'
                  ? 'Kids\' Corner "Tintiri Mintiri"'
                  : 'Детски Кът - "Тинтири Минтири"'}
              </h1>
            </div>
            <div className="row activities-row">
              <div className="col-sm-12 col-md-6">
                <h2>
                  {this.props.lang === 'en'
                    ? 'Games, Creativity and much more ...'
                    : 'Игри, творчество и още нещо…'}
                </h2>
                <p>
                  {this.props.lang === 'en'
                    ? 'During the festival you will be able to leave your kids with our animators. Every day, there will be 3 sessions, in which the little ones will be able to play, create, make friends and have fun.'
                    : 'Мястото на фестивала, където ще може да оставите вашите деца в ръцете на нашите аниматори. Те са подготвили всеки ден по време на фестивала 3 сесии, в които  малчуганите ще могат да играят, да творят, да създават нови приятелства и да се забавляват.'}
                </p>
                <p>
                  {this.props.lang === 'en'
                    ? 'Sessions will include'
                    : 'В сесиите ще има:'}
                </p>
                <ul className="kids-items">
                  {this.props.lang === 'en'
                    ? kidsSessionsEn.map((e) => (
                        <li className="kids-item">{e}</li>
                      ))
                    : kidsSessionsBg.map((e) => (
                        <li className="kids-item">{e}</li>
                      ))}
                </ul>
                <p>
                  {this.props.lang === 'en'
                    ? 'Working hours: 4:00 pm till 9:30 pm'
                    : 'Работно време: 16:00 - 21:30'}
                </p>
                <ul className="kids-items">
                  {this.props.lang === 'en'
                    ? kidsSessionHoursEn.map((e) => (
                        <li className="kids-item">{e}</li>
                      ))
                    : kidsSessionHoursBg.map((e) => (
                        <li className="kids-item">{e}</li>
                      ))}
                </ul>

                <div className="price-container">
                  <span className="price-box">
                    {this.props.lang === 'en'
                      ? 'Price 25  BGN per session'
                      : 'Цена: 25 лв на сесия'}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="row">
                  <div className="col-6 kids-image-box">
                    <div className="activities-image-caption">
                      <a
                        href="https://www.facebook.com/lotusvibe777/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="kids-image"
                          src={LotusVibeLogo}
                          alt="Lotus Vibe Logo"
                        />
                      </a>
                    </div>
                    <div className="activities-image-caption">Lotus Vibe</div>
                  </div>
                  <div className="col-6 kids-image-box">
                    <div className="activities-image-caption">
                      <a
                        href="https://www.facebook.com/p/%D0%90%D0%BA%D0%B0%D0%B4%D0%B5%D0%BC%D0%B8%D1%8F-%D0%94%D0%B5%D1%86%D0%B0%D1%82%D0%B0-%D0%9D%D1%8F%D0%BC%D0%B0%D1%82-%D0%A1%D0%BF%D0%B8%D1%80%D0%B0%D1%87%D0%BA%D0%B8-61558314719032/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="kids-image"
                          src={FuriLogo}
                          alt="Lotus Vibe Logo"
                        />
                      </a>
                    </div>
                    <div className="activities-image-caption">
                      Aкадемия "Децата Нямат Спирачки"
                    </div>
                  </div>
                </div>
                <p>
                  Детският кът се осъществява с любезното съдействие на Lotus
                  Vibe и Академия “Децата Нямат Спирачки”
                </p>
                <div className="row">
                  <div className="col-12">
                    <img
                      className="furi-cover"
                      src={FuriCover}
                      alt="Furioso Cover"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="row activities-row">
              <a
                href="https://www.poletisbalon.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h1 className="col-12 activities-title">
                  {this.props.lang === 'en'
                    ? 'Hot Air Baloon Flights by AEROACT'
                    : 'Полети с балон от АЕРОАКТ'}
                </h1>
              </a>
            </div>
            <div className="row activities-row">
              <div className="col-sm-12 col-md-6">
                {this.props.lang === 'en'
                  ? baloonTextEn.map((e) => <p>{e}</p>)
                  : baloonTextBg.map((e) => <p>{e}</p>)}

                <div className="price-container">
                  <span className="price-box">
                    {this.props.lang === 'en'
                      ? 'Kids Price (up to 12 years old) - 30 BGN'
                      : 'Цена за деца до 12г - 30лв'}
                  </span>
                  <span className="price-box">
                    {this.props.lang === 'en'
                      ? 'Аdults Price - 50 BGN'
                      : 'Цена за възрастни - 50лв'}
                  </span>
                </div>
              </div>

              <div className="col-sm-12 col-md-6">
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <img
                      className="activities-image"
                      src={Balon1}
                      alt="Graffiti  01"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <img
                      className="activities-image"
                      src={Balon2}
                      alt="Graffiti  01"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <img
                      className="activities-image"
                      src={Balon4}
                      alt="Graffiti  01"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <img
                      className="activities-image"
                      src={Balon3}
                      alt="Graffiti  01"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Activities;
