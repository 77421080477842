import React, { Component } from 'react';
import FadeIn from 'react-fade-in';
import { Link } from 'react-router-dom';

class ArtistBox extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.artist);
  }

  render() {
    const scrollTop = 369;
    return (
      <div className="col-md-6 col-sm-12">
        <Link
          to={'/line-up/' + this.props.link}
          onClick={() =>
            window.scrollTo({ top: scrollTop, behavior: 'smooth' })
          }
        >
          <div className="artist-box">
            <FadeIn>
              <div>
                <img
                  className="artist-image"
                  src={this.props.imgSrc}
                  alt={this.props.artist}
                />
              </div>
              <div className="artist-caption">{this.props.artist}</div>
            </FadeIn>
          </div>
        </Link>
      </div>
    );
  }
}

export default ArtistBox;
