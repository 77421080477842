import React, { Component } from 'react';
import FadeIn from 'react-fade-in';
import ArtistsList from '../../ArtistsList';
import { Link } from 'react-router-dom';
import './artist.css';
import FacebookIcon from './fb-icon.svg';
import InstaIcon from './Instagram_icon.png';

class Artist extends Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  render() {
    const scrollTop = 369;
    return (
      <div className="row artist-details-container">
        <div className="artist-text">
          <h1 className="artist-header">{this.props.artist.name}</h1>
        </div>
        <div className="col-12 col-md-4 image-column">
          <FadeIn>
            <div>
              <a
                href={this.props.artist.facebook}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="artist-details-image"
                  src={this.props.imgSrc}
                  alt={this.props.artist.name}
                />
              </a>
            </div>
            <div className="social-icons">
              <span>
                <a
                  href={this.props.artist.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="social-icon"
                    src={FacebookIcon}
                    alt="Facebook Icon"
                  />
                </a>
              </span>
              <span>
                <a
                  href={this.props.artist.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="social-icon"
                    src={InstaIcon}
                    alt="Instagram Icon"
                  />
                </a>
              </span>
            </div>
          </FadeIn>
        </div>

        <div className="col-12 col-md-8">
          <FadeIn>
            <div className="artist-text">
              {this.props.lang === 'en'
                ? this.props.artist.textEn.map((txt) => <p>{txt}</p>)
                : this.props.artist.textBg.map((txt) => <p>{txt}</p>)}
            </div>
            {this.props.secondImage === '' ? (
              <div></div>
            ) : (
              <div className="second-image-container">
                <img
                  className="second-image"
                  src={this.props.secondImage}
                  alt={this.props.artist.name}
                />
              </div>
            )}
            {this.props.youTube === '' ? (
              <div></div>
            ) : (
              <iframe
                width="100%"
                height="400px"
                src={this.props.youTube}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            )}

            <div>
              <Link
                to="/line-up#artists"
                onClick={() =>
                  window.scrollTo({ top: scrollTop, behavior: 'smooth' })
                }
              >
                <div className="back-button">
                  {this.props.lang === 'en'
                    ? '<< Go Back to Line-Up'
                    : '<< Назад към програмата'}
                </div>
              </Link>
            </div>
          </FadeIn>
        </div>
        <ArtistsList />
      </div>
    );
  }
}

export default Artist;
