const artists = {
    skiller: {
        name: 'SkilleR',
        textBg: [
            "SkilleR е артистичното име на забележителния български талант Александър Деянов. През 2012г. в Берлин на \"Beatbox Battle World\", SkilleR става световен шампион в огромна конкуренция, побеждавайки най-добрите бийтбоксьори от 70 държави",
            "Следват дузини други награди и преди всичко концертни участия в Япония, САЩ, Англия, Русия, Италия, Австрия, Швейцария и много други.",
            "В различни формации и колаборации той е свирил на една сцена с величия като Shaggy, Lumidee, Outlandish, Jaba, Noha, Stereo MCs, Transglobal Underground, Foreign Beggars и др.",
            "Неговият изключителен талант и популярност му донесоха над 50 милиона гледания в YouTube и стотици хиляди последователи, давайки му статут на истинска звезда!",
            "На сцената на Ragada Dugada Music Festival той ще гостува със солистката на \"Мистерията на българските гласове\" - Вили Маринова, нямаме търпение да ги чуем..."
        ],
        textEn: [
            "SkilleR is the artistic name of the remarkable Bulgarian talent Alexander Deyanov. In 2012 in Berlin at the \"Beatbox Battle World\", SkilleR became the world champion in a huge competition, defeating the best beatboxers from 70 countries",
            "Dozens of other awards and, above all, concert appearances in Japan, USA, England, Russia, Italy, Austria, Switzerland and many others.",
            "In various formations and collaborations, he has performed on the same stage with world famous artists like Shaggy, Lumidee, Outlandish, Jaba, Noha, Stereo MCs, Transglobal Underground, Foreign Beggars, etc.",
            "His extraordinary talent and popularity have earned him over 50 million views on YouTube and hundreds of thousands of followers, giving him the status of a true star!",
            "On the stage of Ragada Dugada Music Festival  , he will perform with the soloist of \"The Mystery of Bulgarian Voices\" - Vili Marinova, we can't wait to hear them..."
        ],
        facebook: "https://www.facebook.com/skillerbeatbox",
        instagram: "https://www.instagram.com/skiller.official/?hl=bg",
    },
    dj89: {
        name: 'DJ 89',
        textBg: [
            "DJ 89 е псевдонимът на Ангел Петков, по-младият от електронното дуо \"Братята\", което създава с брат си Иван Петков.",
            "Представя техните произведения на живо, които са продуцирани от двамата братя от София, смесвайки различни музикални стилове от съвременната сцена с авторска музика.",
            "Композитор и музикален продуцент на продукцията е Иван Петков, който се грижи за цялостното озвучаване от записа до реализацията на всяка песен.",
            "\"БРАТЯТА\" модернизират българския фолклор, като съхраняват оставеното от предците народно творчество и му придават ново звучене, което има за цел да служи като музикален учебник на следващите поколения.",
            "DJ 89 ще гостува на \"Ragada Dugada Music Festival\" с 20-годишната Натали Стефанова, която нашумя с първата си песен. Младото момиче издаде своята дебютна песен \"Руси Коси\". Жанрът на творбата е модерен фолк и за по-малко от месец има 70 000 гледания в You Tube."
        ],
        textEn: [
            "DJ 89 is the pseudonym of Angel Petkov, the younger of the electronic duo \"The Brothers / Bratyata\", which he created with his brother Ivan Petkov.",
            "It presents their live works, which are produced by the two brothers from Sofia, mixing different musical styles from the contemporary scene with original music.", 
            "Ivan Petkov is the composer and music producer of the production, who takes care of the overall sound design from the recording to the realization of each song.",
            "\"THE BROTHERS\" modernize the Bulgarian folklore, preserving the folk creativity left by the ancestors and giving it a new sound, which aims to serve as a musical textbook for the coming generations.",
            "DJ 89 will perform at \"Ragada Dugada Music Festival\" with the 20-year-old Natalie Stefanova who made a splash with her first song. The young girl, who grew up in Radomir and currently lives in Pernik, released her debut song \"Blonde Hair\". The genre of the work is modern folk and in less than a month it has 70,000 views on You Tube."
        ],
        facebook: "https://www.facebook.com/DJ89BG",
        instagram: "https://www.instagram.com/dj89bg/?hl=bg",
    },
    trigaida: {
        name: 'Trigaida',
        textBg: [
            "Тригайда прави нов прочит на българското музикално наследство, използвайки фолклорни мотиви и песни за основа на своите композиции, вплитайки в тях популярни електронни жанрове като дръм енд бейс, дъбстеп и трифоп.",
            "Проектът \"Тригайда\" обединява таланта и креативността на трима утвърдени музиканти на българската музикална сцена: Георги Маринов – Хорхе (Оратница, Тъпани и гайди, The Groovin’ Pipers), Иван Шопов (известен още като Balkansky, Cooh) и Ася Пинчева ( Оратница, Космически гласове от България )."
        ],
        textEn: [
            "Trigaida makes a new reading of the Bulgarian musical heritage, using folk motifs and songs as the basis of their compositions, interweaving in them popular electronic genres such as drum and bass, dubstep and triphop.",
            "The Trigaida project unites the talent and creativity of three established musicians on the Bulgarian music scene: Georgi Marinov - Jorge (Oratnitza, Tapani i Gaidi, The Groovin' Pipers), Ivan Shopov (also known as Balkansky, Cooh) and Asya Pincheva (Oratnitza, Cosmic Voices from Bulgaria)."
        ],
        facebook: "https://www.facebook.com/trigaidamusic",
        instagram: "https://www.instagram.com/trigaida/",
    },
    shopov: {
        name: 'Ivan Shopov',
        textBg: [
            "Иван Шопов е творец с много лица, той е художник, музикант, продуцент, композитор, естет и иноватор.",
            "Иван Шопов е участвал в над 250 музикални произведения, сред които намираме както солови, така и съвместни албуми. Това го утвърждава като една от водещите фигури в европейския електронен ъндърграунд. Различните му проекти обхващат жанрове като дръм енд бейс, IDM, джаз, ембиънт, техно, рок и класическа музика.",
            "Иван Шопов е издал над 120 винила за някои от най-големите международни лейбъли. Част от собствената му продукция и творбите на артисти, в които той вярва, са издадени чрез неговите два лейбъла Etheraudio Records и ABCD.",
            "Това, което наистина отличава Иван Шопов от другите артисти в неговата област, е начинът, по който смесва традиционен български фолклор с електронна музика и джаз. По този начин той изгражда мост между музикалната история на страната си и настоящите вкусове на своята публика. Сред най-популярните му издания в тази посока са “Канатица” с Авигея, “InFusion” с Теодосий Спасов, “Кукер” и “Оренда” с Бalkansky.",
            "Европейските сцени, които са изпитали музиката на Иван, са многобройни. По време на международните си турнета той достигна до САЩ, Южна Америка, Русия, Азия, Австралия и Нова Зеландия.",
            "Повече информация за Иван Шопов може да намери на неговата уеб страница: https://www.ivanshopov.com/",
            "Очакваме го на 2 август на Бяла Сцена, веднага след изпълнението им на живо с \"Тригайда\""
        ],
        textEn: [
            "Ivan Shopov is an artist with many faces, he’s а painter, musician, producer, composer, aesthete and an innovator.",
            "Ivan Shopov has been involved in over 250 musical releases, among which we find both solo and collaborative albums. This has established him as one of the leading figures in the European electronic underground. His various projects span over genres like drum and bass, IDM, jazz, ambient, techno, rock and classical music.",
            "Ivan Shopov has released over 120 vinyls for some of the biggest international labels. A part of his own production and the works of artists who he believes in can be found released via his two labels Etheraudio Records and ABCD.",
            "What really separates Ivan Shopov from the other artists in his field is the way he’s mixing traditional Bulgarian folklore with electronic music and jazz. This way he’s building a bridge between his country’s musical history and the current tastes of his audience. Among his most popular releases in this direction are “Kanatitsa” with Avigeya, “InFusion” with Theodosii Spassov, “Kuker” and “Orenda” with Бalkansky.",
            "The European stages who’ve experienced Ivan’s music are numerous. During his international tours he’s reached USA, South America, Russia, Asia, Australia and New Zealand.",
            "You can find more info about Ivan Shopov on his web page: https://www.ivanshopov.com/",
            "He will be playing his DJ Set on 02 August right after his live performance with \"Trigaida\""
        ],
        facebook: "https://www.facebook.com/abcd.ivanshopov",
        instagram: "https://www.instagram.com/ivanshopov_cooh/",
    },
    coolden: {
        name: 'Cool Den',
        textBg: [
            "Cool Den се сформират през пролетта на 2018г, след като Ивайло Петров и Никола Симеонов се срещат на \"Витошка\", където Никола свири като уличен музикант. Те не се познават, и след като Ивайло заголаря Никола, те решават да изпеят заедно песента \"No excuses\" на \"Alice in Chains\".",
            "Минава случаен минувач и оставя 20 лв. в шапката. Да си уличен музикант и някой да ти пусне 20 лв. в шапката не е често срещано явление. Приемайки този инцидент като знак от съдбата, момчетата решават да направят група. След спонтанен джем сешън в столично заведение, срещат барабаниста си - Явор Цветанов. Басистът - Васил Андреев става част от групата само 2 месеца преди първото им изпълнение в пълен състав на Никулден същата година. ",
            "Характеризиращи се с чувството си за хумор, но не и с натрапчивата сериозност на песните, Cool Den определят творчеството си с един израз: \"песни за хората\".",
            "Стилистично и текстово, групата е вдъхновена от български групи като \"No More Many More\", \"Wickeda\", \"Kontrol\", \"Django Ze\" и др.",
            "Cool Den са силно повлияни от стилове като фънк, реге, хип-хоп, пънк, както и музикалната вълна от Сиатъл, която се заражда в началото на 90-те.",
            "Изпълненията им на живо са истинска експлозия, очакваме ги на 4 август на Бяла Сцена Банско."
        ],
        textEn: [
            "Cool Den was formed in the spring of 2018 after Ivaylo Petrov and Nikola Simeonov met at \"Vitoshka\” Street in Sofia, where Nikola performed as a street musician. They didn't know each other and after Ivaylo starts chatting with Nikola, they decide to sing a song together (No excuses - Alice in Chains).",
            "A random guy passes by and leaves 20 BGN into the hat. Being a street musician and someone dropping 20 BGN into your hat is not an everyday thing. Taking this incident as a sign of fate, the boys decide to form a band. Later, after a spontaneous jam session in a bar in the capital, they meet their drummer - Yavor Tsvetanov. The bass player - Vasil Andreev became part of the band just 2 months before their first live performance as a complete band on Nikulden that same year.",
            "Characterized by their sense of humor, but not the intrusive seriousness of the songs, Cool Den define their work with one expression: \"songs for the people\".",
            "Stylistically and lyrically, the group is inspired by Bulgarian bands such as No More Many More, Wickeda, Kontrol, Django Ze, etc.",
            "Cool Den is strongly influenced by styles such as funk, reggae, hip-hop, punk, as well as the musical wave from Seattle that originated in the early 90s.",
            "Their live performances are a pure explosion, we expect them on August 4th at White Stage."
        ],
        facebook: "https://www.facebook.com/cooldenband",
        instagram: "https://www.instagram.com/cooldenband/",
    },
    greesh: {
        name: 'Greesh',
        textBg: [
            "Greesh e соловият проект на Гриша Георгиев (Greesh) - музикант, мултиинструменталист, композитор, изпълнител и продуцент, познат от проектите \"Saint Electrics\", \"Matakka / Portokal\" and \"No More Many More\".",
            "В пауър-триото освен Гриша (Greesh) участват музикантите Никола Джоков (Nixon) - ударни, вокал и клавишни. Той е познат от групите \"Gabana\" и \"Bourbon Street\". Както и Йоана Кунева (Yo) - бас и вокал.",
            "Стилът на звученето е алтърнатив рок, замесен с арт фънк, което позволява да се добавят повече текстури и мелодии. Освен парчетата на Greesh може да чуете емблематичните за Портокал песни - Прилеп, Ти, Кадифено Канапе и няколко интерпретации на любими песни.",
            "Greesh ще забият на \"Ragada Dugada Music Festival\" на 3 Август, Бяла Сцена Банско.",
            "Очакваме ви!"
        ],
        textEn: [
            "Greesh is the solo project of Grisha Georgiev (Greesh) - musician, multi-instrumentalist, composer, performer and producer, known from the projects \"Saint Electrics\", \"Matakka / Portokal\" and \"No More Many More\".",
            "In addition to Greesh, the power trio includes the musicians Nikola Djokov (Nixon) - drums, vocals and keyboards, who is known from the groups \"Gabana\" and \"Bourbon Street\". As well as Yoana Kuneva (Yo) - bass and vocals.",
            "Their style is a fusion of alternative rock mixed with Art Funk, allowing for more textures and melodies to be added.",
            "In addition to Greesh's tracks, you can hear Portokal's iconic songs - \"Bat\", \"You\", \"Velvet Canape\" and several interpretations of favorite songs.",
            "Greesh will play at \"Ragada Dugada Music Festival\" on August 3, White Stage Bansko",
            "We are expecting you!"
        ],
        facebook: "https://www.facebook.com/multigreesh",
        instagram: "https://www.instagram.com/grisha.georgiev/"
    },
    qtek: {
        name: 'QTEK Crew',
        textBg: [
            "QTEK не е просто група, а начин на работа, посока, в която се движат по пътя на ъндърграунд хип хопа.",
            "QTEK Crew ще дойдат от Смолян до Банско за Ragada Dugada Music Festival, Бяла Сцена Банско, 4 Август"
        ],
        textEn: [
            "QTEK is not just a band, but a way of working, a direction in which they are moving on the path of underground hip hop.",
            "QTEK Crew will come from Smolyan to Bansko for Ragada Dugada Music Festival, White Stage Bansko, August 4"
        ],
        facebook: "https://www.facebook.com/Crew.Smolyan/",
        instagram: "https://www.instagram.com/ragada_dugada_official",
    },
    skill: {
        name: 'DJ Skill',
        textBg: [
            "DJ Skill е роден и базиран в Пловдив диджей, продуцент, бийт-мейкър, олдскул рапър, радио-водещ, меломан, колекционер на грамофонни плочи и пионер в откриването на прочувствени, фънк и джази хип-хоп тракове в България от началото на 90-те.",
            "Skill ще пуска по време на \"Ragada Dugada Battle\", на 4 август, Бяла Сцена Банско. Пригответе се за много фънки бийтове каквито можем да чуем само на един истински ъндърграунд хип-хоп батъл!"
        ],
        textEn: [
            "DJ Skill is Plovdiv born and based DJ, producer, beatmaker, oldschool rapper, radio host, music addict, vinyl collector, crate digger and pioneer in the purveyance of soulful, funky and jazzy hip-hop & experimental grooves in Bulgaria since the early 90s.",
            "Skill will be DJing during \"Ragada Dugada Battle\", White Stage Bansko, 4 August. Get ready for a lots of fukny breaks and beats for a real underground HIP-HOP battle!"  
        ],
        facebook: "https://www.facebook.com/SKILL032",
        instagram: "https://www.instagram.com/ragada_dugada_official",
    },
    alphabet: {
        name: 'DJ Alphabet',
        textBg: [
            "DJ Alphabet е търнтейбълист, меломан и винаги ровещ за някоя свежа плоча, която да поскърца на следващото си участие. Той е един от най-техничните диджеи в България и определено трябва да чуете неговите скречинг умения зад грамофоните!",
            "Той ще ни резцепи с бийтове на Ragada Dugada Music Festival на 4 август."
        ],
        textEn: [
            "DJ Alphabet is a Turntablist, Mixologist, Crate Digger. He is one of the most technically skilled DJs in Bulgaria and you deffinetely need to hear his scracthing skills on the wheels of steel!",
            "He will be DJing at Ragada Dugada Music Festival on 04 August." 
        ],
        facebook: "https://www.facebook.com/DJAlphabet/",
        instagram: "https://www.instagram.com/djalphabett/",
    },
    samm_the_man: {
        name: 'Samm The Man',
        textBg: [
            "Samm The Man започва като би-бой през 1999, което го отваря към музикалния свят и години по-късно към диджейнга. От 2012та до сега той е активен диджей на българската сцена. Резидент на редица места в столицата, той също така пуска на много брейк и хипхоп събития в страната.",
            "Музикалният му вкус е широк, затова и неговите сетове често са богати на различни стилове.",
            "Samm The Man ще забие на \"Бяла Сцена Банско\" на 4 август. \"Музикален Фестивал Рагада Дугада\", очакваме ви ..."
        ],
        textEn: [
            "Samm The Man started as a BBoy in 1999, which led him to the world of music and years later to DJing. Since 2012 until now, he is an active DJ on the Bulgarian scene. A resident of a number of clubs in the capital, he also plays at many break and hip hop events around Bulgaria.",
            "His musical taste is broad, so his sets are often rich in different styles.",
            "Samm The Man will play at \"White Stage Bansko\" on 4th of August. Ragada Dugada Music Festival. Come and join us .."
        ],
        facebook: "https://www.facebook.com/samuil.topalov.1",
        instagram: "https://www.instagram.com/dj_sammtheman/",
    },
    deep_george: {
        name: 'Deep George',
        textBg: [
            "Deep George е диджей, фокусиран върху дийп хаус музиката.",
            "Той е в играта още от 90-те и за нас ще бъде чест да имаме един истински пионер на българската клубна сцена.",
            "Deep George ще открие нощната част на Рагада Дугада Фест в събота на 3 Август.",
        ],
        textEn: [
            "Deep George is a DJ focused on deep house music.",
            "He's been DJing since the 90's and we are honored to have a true pioneer on the Bulgarian club scene playing at Ragada Dugada Music Festival.",
            "Deep George, 3 August"
        ],
        facebook: "https://www.facebook.com/deepgeorgeofficial/",
        instagram: "https://www.instagram.com/ragada_dugada_official",
    },
    vataff_project: {
        name: 'Vataff Project',
        textBg: [
            "Vataff Project е създаден през 2001 г. Никога не е имало фиксиран състав. Виктор Маринов, основателят на проекта, от време на време се присъединява към музиканти, за да свирят на негови концерти на живо. Проектът постигна успех по целия свят с висок клас аналогов звук.",
            "За повече информация относно Vataff Project посетете уебсайта vataffproject.com"
        ],
        textEn: [
            "Vataff Project formed in 2001. There has never been a fixed line-up. Victor Marinov, the founder of the project, occasionally collaborates with musicians for playing his live gigs. The project attained success around the world with high-end analogue sound.",
            "For more info regarding Vataff Project visit the website vataffproject.com"
        ],
        facebook: "https://www.facebook.com/vataffproject/",
        instagram: "https://www.instagram.com/ragada_dugada_official",
    },
    silent_citizen: {
        name: 'Silent Citizen',
        textBg: [
            "Страстен към МУЗИКАТА и по-дълбокия смисъл зад парчетата. Мартин Николов е истинското име на човека, който стои зад Silent Citizen.",
            "Мълчалив на думи, но активен в действия гражданин на света. Обича да споделя вкуса си към музиката и енергията си с тълпата.",
            "Той винаги мисли за музикалния сет като за история, която трябва да има \"експозиция\", където слушателят да може да влезе плавно в \"усложнението\", в което е въведен конфликтът на историята. Едва след това той може да преживее \"възходящото действие\" и \"кулминацията\".",
            "Както всичко в живота и тук, историята има своя край. По-точно следва наистина плавно \"разрешение\" и слушателят е изваден от състоянието на транс и въведен отново в реалния свят, за да може да се върне у дома със себе си преживяване, чисто блаженство, красива история, която той не може да обясни или разкаже :)"
        ],
        textEn: [
            "Passionate about MUSIC and the deeper meaning behind the tracks. Martin Nikolov is the real name of the person who stands behind Silent Citizen.",
            "A silent in words but active in action citizen of the world. Love to share his taste of music and energy with the crowd.",
            "He always thinks of a music set as a story that needs to have an \"exposition\" where the listener can enter smoothly into the \"complication\" in which is introduced the conflict of the story. Only after this can he experience the \"rising action\" and the \"climax\".",
            "As everything in life here as well, the story comes to an end. To wit a really smooth \"resolution\" follows and the listener is taken out of the trance state and introduced again into the real world so he can bring home with him an experience, a pure bliss, a beautiful story that he can't explain or tell :)"
        ],
        facebook: "https://www.facebook.com/silentcitizendj/",
        instagram: "https://www.instagram.com/ragada_dugada_official",
    },
    gatoff: {
        name: "[gatof]",
        textBg: [
            "Красимир Костадинов, стоящ зад псевдонима [gatof], започва музикалната си кариера през 2001 година, като организатор и на партита в Плевен както и като DJ в стила PsyTrance и Progressive Trance. Първият му албум е издаден през 2001 година в ограничен тираж. През годините се изявява като DJ в страната и има няколко участия в Шотландия.",
            "През 2019 се връща към музикалната продукция и започва да работи по собствен проект, който не след дълго се трансформира и в Liveset в областта на Psy, Progressive и Tech Trance стиловете. В момента участва в различни проекти с музиканти от цялата страна, някои от тях са Coffee Shot, дуо с Радослава Владимирова, както и в група с музиканти от Банско и страната.",
            "Повече информация за Краси може да намерите на неговата уеб страница: https://www.krasimirkostadinov.net/"
        ],
        textEn: [
            "Krasimir Kostadinov, behind the pseudonym [gatof], began his musical career in 2001, as an organizer of parties in Pleven and as a DJ in the style of PsyTrance and Progressive Trance. His first album was released in 2001 in a limited edition. Over the years he has performed as a DJ in the country and has made several appearances in Scotland.",
            "In 2019, he returned to music production and started working on his own project, which soon transformed into Liveset in the field of Psy, Progressive and Tech Trance styles. Currently, he participates in various projects with musicians from all over the country, some of them are Coffee Shot, a duo with Radoslava Vladimirova, as well as in a band with musicians from Bansko and the country.",
            "You can find more info about Krasi on his web page: https://www.krasimirkostadinov.net/"
        ],
        facebook: "https://www.facebook.com/gatohato",
        instagram: "https://www.instagram.com/_gatof_",
    },
    kristoffer_beatrider: {
        name: "Kristoffer Beatrider (Funkadelia)",
        textBg: [
            "Kristoffer Beatrider е организатор и диджей от старата школа, активен най-вече в периода 2006 - 2016г. Последните му изяви на сцена бяха миналото лято на фестивалите IRIDA и Sun Tribe, където се представи под псевдонима Gayatri.",
            "На Ragada Dugada очаквайте от него динамичен, мелодичен Psy Trance сет - своеобразно астрално пътешествие, което ще промени състоянието на духа ви и ще остави отпечатък в съзнанието ви."
        ],
        textEn: [
            "Kristoffer Beatrider is an old-school promoter and DJ, active mostly between 2006 and 2016. His last stage appearances were last summer at the IRIDA and Sun Tribe festivals, where he performed under the name Gayatri.",
            "At Ragada Dugada, expect a dynamic, melodic Psy Trance set - an astral journey that will change your state of consciousness and leave an imprint on your mind."
        ],
        facebook: "https://www.facebook.com/groups/funkadelia/",
        instagram: "https://www.instagram.com/ragada_dugada_official",
    },
    beatoven: {
        name: 'Beatoven',
        textBg: [
            "Beatoven e колекционер/селектор от София с голяма страст подбира реге и хип хоп грамофонни плочи за да гарантира доброто ни настроение",
            "Beatoven ще открие нашата програма в събота на 3 август с реге селекция на грамофонни плочи. Рагада Дугада!"
        ],
        textEn: [
            "Beatoven is a collector/selector from Sofia with a great passion for selecting reggae and hip hop records to guarantee our good mood",
            "Beatoven is the firts DJ in our Line Up for Saturday 3rd of August with a selection of reggae vinyl records. Ragada Dugada!"
        ],
        facebook: "https://www.facebook.com/Biggahead",
        instagram: "https://www.instagram.com/beatovenselectah",
    },
    extazia: {
        name: 'EXTAZIA',
        textBg: [
            "EXTAZIA е дуо диджеи, създадено с една мисия - да съчетае грууви dance звучене с хипнотични техно ритми и енергични брейкбийтове. Вдъхновени от разнообразието и богатството на електронната музика, EXTAZIA предлагат уникално музикално изживяване, което завладява както тялото, така и духът.",
            "Съставено от двама страстни музикални ентусиасти, Антонио и Теодора. Техните сетове са внимателно подбрани и изпълнени с динамика, която гарантира незабравими моменти на дансинга.",
            "Със своя иновативен подход и желание за експериментиране, Extazia привлича внимание и създава силна връзка с публиката. Независимо дали сте дългогодишен фен на електронната музика или новак в жанра, сетовете на EXTAZIA ще ви отведат на едно музикално пътешествие, което няма да забравите."
        ],
        textEn: [
            "EXTAZIA is a DJ duo created with one mission - to combine groovy dance sound with hypnotic techno rhythms and energetic breakbeats. Inspired by the diversity and richness of electronic music, EXTAZIA offers a unique musical experience that conquers both body and spirit.",
            "EXTAZIA is created by two passionate music enthusiasts, Antonio and Teodora. Their sets are carefully selected and filled with dynamics that guarantee unforgettable moments on the dance floor.",
            "With its innovative approach and willingness to experiment, Extazia draws attention and creates a strong connection with the audience. Whether you are a long-time fan of electronic music or a newcomer to the genre, EXTAZIA's sets will take you on a musical journey you will not forget."
        ],
        facebook: "https://www.facebook.com/profile.php?id=100089105133966",
        instagram: "https://www.instagram.com/extazia_faza?igsh=Ym1qbXdnMGthc3U5"
    },
    dylan_kay: {
        name: 'Dylan Kay',
        textBg: [
            "Дилън Кей, родом от Ирландия, живеещ в Банско, е бил зад ди джейския пулт както в Ирландия, така и в България. Той е уважаван хаус и техно диджей.",
            "Слушайте Dylan Kay BNKR Techno Set - 15/07/23 от Dylan Kay на #SoundCloud https://on.soundcloud.com/WH4HA", 
            "Oчакваме го в събота на 3ти август!"
        ],
        textEn: [
            "Dylan Kay, originally from Ireland, now a Bansko resident, has been behind the decks both in Ireland and Bulgaria. He's a well respected house & techno DJ, with some of his own tracks that make an appearance every so often!",
            "Listen to Dylan Kay BNKR Techno Set - 15/07/23 by Dylan Kay on #SoundCloud https://on.soundcloud.com/WH4HA"
        ],
        facebook: "https://www.facebook.com/events/798180282419173",
        instagram: "https://www.instagram.com/ragada_dugada_official"
    },
    steven: {
        name: 'DJ Steven - Metropolis, MKNK Recordings',
        textBg: [
            "DJ Стивън се занимава професионално с музика от 1990 година, като още от първите си участия печели сърцата на публика и критика.",
            "Резултатите не закъсняват и той става победител на първия DMC шампионат в България през 1993 година. Едновременно с това е обявен за DJ No 1 през 1993, 1994, 1997 и 2002 година.",
            "През 1997 Стивън се присъединява към Метрополис – най-популярното и днес движение за електронна музика у нас, чийто успех се дължи до голяма степен и на безкрайно енергичната и интересна музика, с която той омагьосва публиката. Музика, която черпи вдъхновение поравно и от класическия фънк и от Детройтското техно с емблематичния звук на ритъм машините TR 808 и TR 909. Самият той признава, че няма търпение да докара клъбърите до еуфория и че любимите му моменти са онези, в които всички са \"на гребена на вълната\".", 
            "Всичко това е отдавна доказано от многобройните му участия из цялата страна, както и от гиговете му в Чехия, Македония, Германия, Австрия, Италия, Англия и Америка, както и от статуетката за най-добър хаус DJ за 2009 година на наградите на SEEME.",
            "Паралелно с клубните си участия, DJ Стивън работи упорито и в студиото си The Clock, където продуцира авторска музика и ремикси, като ремиксът на “Faded” от Gravity Co също печели награда за най-добър ремикс на наградите на SEEME 2009.",
            "Facebook: https://www.facebook.com/steven.metropolis",
            "Facebook Musician Page: https://www.facebook.com/djstevenstevokurt",
            "Soundcloud: https://soundcloud.com/dj-steven",
            "Mixcloud: https://www.mixcloud.com/DJ_Steven_Metropolis/"
        ],
        textEn: [
            "DJ Steven started DJ-ing professionally in 1987. The influence from the early stars of hip-hop like Grandmaster Flash, Kool Herc and Afrika Bambaataa. The art of DJ-ing took him to the finals of the first Bulgarian DMC championship in 1993 where he became DMC champion together with his team Tall, Fat & Small. In 1994 they took part in the DMC World finals at the Ministry of Sound, London. The same year DJ Steven was voted best DJ in Bulgaria for the first time.",
            "The rise of house and techno, the energy of German legends Krafwerk and seminal tracks like L’Esperanza and Harlequin: The Beauty and The Beast by Sven Vath and Don’t Laugh by Winx got DJ Steven deeply interested in the even flow of the kick drum. He started playing lots of house & techno in his sets. In 1996 after being kicked out from all clubs in the country, DJ Steven and a group of friends and DJs started organizing and promoting parties under the name of Metropolis. Now Metropolis is the biggest electronic dance music promoter in Bulgaria with a 24 year history.",
            "DJ Steven is one of the main Metropolis residents and played alongside with Carl Cox, Sven Vath, John Digweed, Sasha, Deep Dish, Jeff Mills, Josh Wink, Luke Slater, Ben Klock, Rødhåd. He DJ-ed in Macedonia, Romania, Czech Republic, Turkey, Poland, Berlin Love Parade, London, Miami, Chicago and New York. DJ Steven was voted best Bulgarian DJ 1993, 1994, 1997 and 2002. In June 2009 he won Best House DJ award at SEEME (South East European Music Event).",
            "Facebook: https://www.facebook.com/steven.metropolis",
            "Facebook Musician Page: https://www.facebook.com/DJ-Steven-Metropolis-Official-164490786898819/",
            "Soundcloud: https://soundcloud.com/dj-steven",
            "Mixcloud: https://www.mixcloud.com/DJ_Steven_Metropolis/"
        ],
        facebook: "https://www.facebook.com/steven.metropolis",
        instagram: "https://www.instagram.com/stevokurt_djsteven/"
    },
    dario: {
        name: 'Dario Bulgario',
        textBg: [
            "Dario Bulgario е мултидисциплинарен артист, който се отличава със своето уникално музикално творчество, изследващо връзките между електронната и акустичната музика.",
            "Чрез използването на синтезатори и разнообразни музикални инструменти, той създава музика в реално време, което позволява на слушателите да се потопят в индивидуално усещане и разбиране за музикалния синтез.",
            "Стиловете, в които той твори, варират от downtempo и ambient до техно и транс, като по този начин предлага широк спектър от звукови изживявания. Творчеството му е не само експериментално, но и дълбоко емоционално, което кара слушателите да преосмислят своето възприятие за музика и звуково изкуство."
        ],
        textEn: [
            "Dario Bulgario is a multidisciplinary artist who stands out for his unique musical work exploring the connections between electronic and acoustic music.",
            "Through the use of synthesizers and a variety of musical instruments, he creates music in real time, allowing listeners to immerse themselves in an individual feel and understanding of musical synthesis.",
            "The styles in which he creates varies from downtempo and ambient to techno and trance, thus offering a wide range of sonic experiences. His work is not only experimental but also deeply emotional, making listeners rethink their perception of music and sound art."
        ],
        facebook: "https://www.facebook.com/events/798180282419173",
        instagram: "https://www.instagram.com/ragada_dugada_official"
    },
}

export default artists;