import React, { Component } from 'react';

class AccuracyEn extends Component {

    render() {
		return (
            <div className='row activities-row'>
                <div className='col-12'>
                    <h1>Paragliding Accuracy Bansko 2024</h1>
                    <h3>01 - 04 August, 2024, Bansko, Bulgaria</h3>
                    <p>Paragliding Accuracy competition in the beautiful mountain resort - Bansko.
                        All pilots, who are interested in this discipline, and cover the required skills and experience are welcome to participate.
                        Our goal is to have 12 complete rounds during the time available.
                    </p>
                    <iframe className='accuracy-video' width="100%" height="400px" src="https://www.youtube.com/embed/1ayoiYK8p1g?si=EFlsquG2YnG9F0vc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div className='col-sm-12 col-md-6'>
                    <h2>Competition Program</h2>
                    <ul>
                        <li>01 August - Training Day</li>
                        <li>02 August - Competition Day
                            <ul>
                                <li>09:00 - 10:00 - Registration/ Briefing/Traning round</li>
                                <li>10:00 - 18:00 - Contest flights</li>
                            </ul>
                        </li>
                        <li>03 August - Competition Day
                            <ul>
                                <li>08:00 - 18:00 - Contest flights</li>
                                <li>19:00 - Prize giving and closing ceremony</li>
                            </ul>
                        </li>
                        <li>04 August - Reserve Day</li>
                    </ul>
                    <p>The HQ of the event will be at Bunderishka polyana (top station of Gondola Lift).
                        Any modifications or changes will be announced in time by the organizers on the General Information Board at the Headquarters, or by the Meet Director at the Briefings.
                    </p>
                </div>
                <div className='col-sm-12 col-md-6'>
                    <h2>Security Rules</h2>
                    <p>Only experienced accuracy pilots, can participate. The organizer reserve the right to deny entry for pilot without proven accuracy experience.</p>
                    <h2>Entry fee payment CHF 50</h2>
                    <p>Entry fee must be paid in cash during Local Registration.</p>
                    <h2>Limitations</h2>
                    <p>Only certificated paragliders in class EN - A, EN - B, EN - C, or its equivalent will be allowed.</p>
                    <h2>Flying Site</h2>
                    <ul>
                        <li>Take-off: 2230 m AMSL. Suitable wind directions: N, NW, NE</li>
                        <li>Landing: Banderiska poljana, 1600 m AMSL. Grass field, approachable from all directions.</li>
                    </ul>
                    <h2>Organizers</h2>
                    <ul>
                        <li>Valey Tzvetanov</li>
                        <li>Ivan Kalibatsev</li>
                    </ul>
                    <h2>Contacts</h2>
                    <ul>
                        <li>Valery Tzvetanov</li>
                        <li>+359899914801</li>
                        <li>freefever@abv.bg</li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default AccuracyEn;