import React, { Component } from 'react';
// import FadeIn from 'react-fade-in';

import BanksoLogo from './bansko_logo.png';
import HouseLogo from './the_house.png';
import UlenLogo from './logo-bansko.jpg';
import StripesLogo from './strypes.png';
import Logo360 from './logo-360.png';
import CastelloLogo from './castello.jpg';
import coworkingLogo from './coworking.png';
import colivingLogo from './coliving.jpg';
import networkingLogo from './networking.png';
import FM_Logo from './logo_fmplus.png';
import FRESH_Logo from './logo_fresh.png';
import GHBansko from './grand-hotel-bansko.png';
import GHTherme from './grand-hotel-therme.jpg';
import GrandRoyale from './The-Grand-Royale-Hotel--Spa.png';
import poster from './new-poster.jpeg';

import './partners.css';

class Partners extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="col-12 text-center mt-5 ">
          <img
            className="poster"
            src={poster}
            alt="logo"
            style={{ maxWidth: '100%' }}
          />
        </div>
        <div className="partners">
          <h2 className="partners-title col-12">
            {this.props.lang === 'en'
              ? 'Ragada Dugada Music Festival is supported by'
              : 'Музикален Фестивал Рагада Дугада се осъществява с подкрепата на'}
          </h2>
          <div className="primary">
            <div className="item">
              <a
                href="https://www.bansko.bg/bg/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={BanksoLogo} alt="" className="partners-img main" />
              </a>
            </div>

            <div className="item">
              <a
                href="http://fmplus.net/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={FM_Logo} alt="" className="partners-img main" />
              </a>
            </div>

            <div className="item">
              <a
                href="http://radiofresh.bg/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={FRESH_Logo} alt="" className="partners-img main" />
              </a>
            </div>
          </div>

          <div className="secondary">
            <div className="item">
              <a
                href="https://www.networking.space/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={networkingLogo} alt="" className="partners-img" />
              </a>
            </div>

            <div className="item">
              <a
                href="https://www.facebook.com/coworkingbansko"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={coworkingLogo} alt="" className="partners-img" />
              </a>
            </div>
            <div className="item">
              <a
                href="https://www.facebook.com/coworkingbansko"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={colivingLogo} alt="" className="partners-img" />
              </a>
            </div>

            <div className="item">
              <a
                href="https://banskoski.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={UlenLogo} alt="" className="partners-img" />
              </a>
            </div>

            <div className="item">
              <a
                href="https://www.360mag.bg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Logo360} alt="" className="partners-img" />
              </a>
            </div>

            <div className="item">
              <a
                href="https://www.facebook.com/thehousebansko"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={HouseLogo} alt="" className="partners-img" />
              </a>
            </div>

            <div className="item">
              <a
                href="https://www.facebook.com/p/Castello-Restaurant-Bansko-100088114913766/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={CastelloLogo} alt="" className="partners-img" />
              </a>
            </div>

            <div className="item">
              <a
                href="https://strypes.eu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={StripesLogo} alt="" className="partners-img" />
              </a>
            </div>

            <div className="item">
              <a
                href="https://grandhotel.bg/grand-hotel-bansko/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={GHBansko} alt="" className="partners-img" />
              </a>
            </div>

            <div className="item">
              <a
                href="https://grandhotel.bg/grand-hotel-terme/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={GHTherme} alt="" className="partners-img" />
              </a>
            </div>
            <div className="item">
              <a
                href="http://www.grandroyale.bg/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={GrandRoyale} alt="" className="partners-img" />
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Partners;
