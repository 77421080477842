import React from 'react';

var cookieBannerSliderPos = 0;

function showCookieBanner() {
  var cookiebanner = document.getElementById('cookiebanner');
  var dialogHeight = parseInt(cookiebanner.offsetHeight);
  cookiebanner.style.bottom = cookieBannerSliderPos - dialogHeight + 'px';
  cookieBannerSliderPos += 4;
  if (cookieBannerSliderPos < dialogHeight) {
    setTimeout(function () {
      showCookieBanner();
    }, 1);
  } else {
    cookieBannerSliderPos = 0;
    cookiebanner.style.bottom = '0px';
  }
}

function hideCookieBanner() {
  var cookiebanner = document.getElementById('cookiebanner');
  cookiebanner.style.display = 'none';
}

function Footer(props) {
  return (
    <div className="footer p-2 fixed">
      <div className="col-12">
        <div
          id="cookiebanner"
          lang="[#LANGUAGE#]"
          dir="[#TEXTDIRECTION#]"
          ng-non-bindable
        >
          <div id="c-left">
            <p class="c-header">[#TITLE#]</p>
            <p class="c-message">[#TEXT#]</p>
          </div>
          <div id="c-right">
            <a
              href="javascript:void(0)"
              onclick="Cookiebot.dialog.submitConsent()"
              class="c-button"
            >
              OK
            </a>
          </div>
          {/* <div style="clear:both"></div> */}
        </div>
      </div>
      <div className="col-12 text-center text-black">
        <div className="tickets-buy">
          <a
            href="https://bilet.bg/bg/cart/ragada-dugada-music-festival-5244-5244"
            target="_blank"
            rel="noopener noreferer"
          >
            {props.lang === 'bg' ? 'Купи Билет' : 'Buy Ticket'}
          </a>
        </div>
        <div>Ragada Dugada &copy;2024</div>
      </div>
    </div>
  );
}

export default Footer;
