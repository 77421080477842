import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class Menu extends Component {
  render() {
    const scrollTop = 369;
    return (
      <ul className="navbar-nav mr-auto">
        <li className="nav-item">
          <NavLink
            activeClassName="active"
            exact
            className="nav-link"
            to="/"
            onClick={() =>
              window.scrollTo({ top: scrollTop, behavior: 'smooth' })
            }
          >
            {this.props.lang === 'en' ? 'Home' : 'Рагада Дугада'}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            activeClassName="active"
            exact
            className="nav-link"
            to="/line-up"
            onClick={() =>
              window.scrollTo({ top: scrollTop, behavior: 'smooth' })
            }
          >
            {this.props.lang === 'en' ? 'Line Up' : 'Програма'}
          </NavLink>
        </li>
        {/* <li className="nav-item">
          <NavLink
            activeClassName="active"
            className="nav-link"
            to="/battle"
            onClick={() =>
              window.scrollTo({ top: scrollTop, behavior: 'smooth' })
            }
          >
            {this.props.lang === 'en'
              ? 'Ragada Dugada Battle'
              : 'Рагада Дугада Батъл'}
          </NavLink>
        </li> */}
        <li className="nav-item">
          <NavLink
            activeClassName="active"
            className="nav-link"
            to="/activities"
            onClick={() =>
              window.scrollTo({ top: scrollTop, behavior: 'smooth' })
            }
          >
            {this.props.lang === 'en' ? 'Activities' : 'Aктивности'}
          </NavLink>
        </li>
        {/* <li className="nav-item">
					<NavLink activeClassName='active' className="nav-link" to="/white-stage" onClick={() => window.scrollTo({ top: scrollTop, behavior: 'smooth' })}>
						{this.props.lang === 'en' ? "White Stage" : "Бяла Сцена"}
					</NavLink>
				</li> */}
        <li className="nav-item">
          <NavLink
            activeClassName="active"
            className="nav-link"
            to="/tickets"
            onClick={() =>
              window.scrollTo({ top: scrollTop, behavior: 'smooth' })
            }
          >
            {this.props.lang === 'en' ? 'Tickets' : 'Билети'}
          </NavLink>
        </li>
      </ul>
    );
  }
}

export default Menu;
