import React, { Component } from 'react';
import FadeIn from 'react-fade-in';
import TicketsPoster from './tickets-poster.png';
// import PromoEn from '../promo-en.gif';
// import PromoBg from '../promo-bg.gif';
import './tickets.css';

class Tickets extends Component {
  render() {
    return (
      <FadeIn>
        <a
          className="rd-link"
          href="https://bilet.bg/bg/cart/ragada-dugada-music-festival-5244-5244"
          target=" _blank"
          rel="noopener noreferrer"
        >
          <div className="tickets">
            <h1 className="tickets-header">
              {this.props.lang === 'en'
                ? 'Ragada Dugada Music Festival Prices'
                : 'Цени за Музикален Фестивал Рагада Дугада'}
            </h1>
            <div className="row">
              <div className="col-12">
                <div className="ticket-info">
                  {this.props.lang === 'en'
                    ? 'Buy Tickets Online from bilet.bg'
                    : 'Купете билет онлайн от bilet.bg'}
                </div>
              </div>
            </div>
            <div className="row header-row">
              <div className="col-4">
                <strong>
                  {this.props.lang === 'en' ? 'Tickets' : 'Билети'}
                </strong>
              </div>

              <div className="col-4">
                <strong>
                  {this.props.lang === 'en' ? 'Online' : 'Онлайн'}
                </strong>
              </div>
              <div className="col-4">
                <strong>
                  {this.props.lang === 'en' ? 'At the Festival' : 'На вход'}
                </strong>
              </div>
            </div>
            <div className="row ticket-row">
              <div className="col-4">
                <div>{this.props.lang === 'en' ? '3 Days' : '3 дни'}</div>
              </div>

              <div className="col-4">
                <div>65 {this.props.lang === 'en' ? 'BGN' : 'лв'}</div>
              </div>
              <div className="col-4">
                <div>80 {this.props.lang === 'en' ? 'BGN' : 'лв'}</div>
              </div>
            </div>
            <div className="row ticket-row">
              <div className="col-4">
                <div>02 {this.props.lang === 'en' ? 'August' : 'Август'}</div>
              </div>

              <div className="col-4">
                <div>35 {this.props.lang === 'en' ? 'BGN' : 'лв'}</div>
              </div>
              <div className="col-4">
                <div>45 {this.props.lang === 'en' ? 'BGN' : 'лв'}</div>
              </div>
            </div>
            <div className="row ticket-row">
              <div className="col-4">
                <div>03 {this.props.lang === 'en' ? 'August' : 'Август'}</div>
              </div>

              <div className="col-4">
                <div>25 {this.props.lang === 'en' ? 'BGN' : 'лв'}</div>
              </div>
              <div className="col-4">
                <div>35 {this.props.lang === 'en' ? 'BGN' : 'лв'}</div>
              </div>
            </div>
            <div className="row ticket-row">
              <div className="col-4">
                <div>04 {this.props.lang === 'en' ? 'August' : 'Август'}</div>
              </div>

              <div className="col-4">
                <div>25 {this.props.lang === 'en' ? 'BGN' : 'лв'}</div>
              </div>
              <div className="col-4">
                <div>35 {this.props.lang === 'en' ? 'BGN' : 'лв'}</div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <img
                  className="tickets-poster"
                  src={TicketsPoster}
                  alt="Tickets Poster"
                />
              </div>
            </div>
            {/* <div className='row'>
                            <div className='col-12'>
                                <img className='tickets-poster' src={this.props.lang === 'en' ? PromoEn : PromoBg} alt="Tickets Poster" />
                            </div>
                        </div> */}
          </div>
        </a>
      </FadeIn>
    );
  }
}

export default Tickets;
