import React, { Component } from 'react';
import FadeIn from 'react-fade-in';
import WhiteStage01 from './biala-scena-1.JPG'
import WhiteStage02 from './biala-scena-2.png'
import WhiteStage03 from './biala-scena-3.png'
import WhiteStage04 from './biala-scena-4.png'

class WhiteStage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			comment: ""
		};

	}

	render() {
		return (
			<div className='white-stage'>
				<div>
					<a className='white-stage-link mb-5' href='https://www.facebook.com/bialascena/' target="_blank" rel="noopener noreferrer">
						<h1>
							{this.props.lang === "en"
								? "White Stage Bansko"
								: "Бяла Сцена Банско"}
						</h1>
					</a>
				</div>
				<FadeIn>
					<div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
						<ol className="carousel-indicators">
							<li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
							<li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
							<li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
							<li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
						</ol>
						<div className="carousel-inner">
							<div className="carousel-item active">
								<img className="d-block w-100 stage-image" src={WhiteStage01} alt="First slide" />
							</div>
							<div className="carousel-item">
								<img className="d-block w-100 stage-image" src={WhiteStage02} alt="Second slide" />
							</div>
							<div className="carousel-item">
								<img className="d-block w-100 stage-image" src={WhiteStage03} alt="Third slide" />
							</div>
							<div className="carousel-item">
								<img className="d-block w-100 stage-image" src={WhiteStage04} alt="Fourth slide" />
							</div>
						</div>
						<a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
							<span className="carousel-control-prev-icon" aria-hidden="true"></span>
							<span className="sr-only">Previous</span>
						</a>
						<a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
							<span className="carousel-control-next-icon" aria-hidden="true"></span>
							<span className="sr-only">Next</span>
						</a>
					</div>
				</FadeIn>
				<div className='stage-info'>
					<p>
						{this.props.lang === 'en'
							? "WHITE STAGE is an innovative space for outdoor public and cultural events. Ragada Dugada Music Festival has the privilege to be the first big event for this fantastic party place. We hope that having all those great artists coming to the festival will fill the space in front of the stage for the very first time. Watch the below video to get inspired for Ragada Dugada Music Festival. In case you don't see it, please refresh the page."
							: "Бяла Сцена е иновативно пространство за публични и културни събития на открито. Музикалният фестивал Ragada Dugada има привилегията да бъде първото голямо събитие за това фантастично парти място. Надяваме се, че програмата ни с всички тези страхотни артисти, ще запълни пространството пред сцената на фестивала за първи път. Гледайте видеоклипа по-долу, за да се вдъхновите за музикалния фестивал Ragada Dugada. В случай че не се вижда, може да презаредите страницата:))"
						}
					</p>
				</div>
				{/* <hr /> */}
				<div className="fb-video" data-href="https://fb.watch/t7Qm0tLx5x/" data-width="700" data-show-text="false">
					<div className="fb-xfbml-parse-ignore">

					</div>
				</div>
				<div className='stage-info text-center'>
					<h1>
						{this.props.lang === "en"
							? "WHITE STAGE BANSKO, 2-4 August"
							: "Бяла Сцена Банско, 2-4 Август"
						}
						<br />
						{this.props.lang === "en"
							? "Ragada Dugada Music Festival"
							: "Музкален Фестивал Рагада Дугада"
						}
					</h1>
				</div>
				<hr />
			</div>
		);
	}
}

export default WhiteStage;